import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getPurchaseTypeIcon, returnPriceWithCurrency, creditRedemptionNoteStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import SignaturePad from "../../app/components/form/SignaturePad";
import Borderline from "../../app/components/form/BorderLine";

const CreditRedemptionNoteRequestDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { crnRequestStore, commonStore } = useStore();
  const { successMessage, loading, setLoading, setSuccessMessage } = commonStore;
  const { crnRequestDetail, setCRNRequestDetail, getCRNRequestWithId, signCRNRequest, reviewCRNRequest } = crnRequestStore;  
  const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "CreditRedemptionNoteRequest" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CreditRedemptionNoteRequest" }), urlPath: RoutesList.creditRedemptionNoteRequest }];
  const crnRequestFullDetail = crnRequestDetail || {
    id: "",
    documentNo: "",
    businessEntityId: "",
    businessEntityName: "",
    branchId: "",
    branchName: "",
    customerId: "",
    customerName: "",
    subBrandId: "",
    subBrandName: "",
    status: "",
    totalAmount: 0,
    purchaseCreditAmount: 0,
    exchangedCreditAmount: 0,
    consultantIds: [],
    consultants: [],
    subTotalAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    roundingAmount: 0,
    note: "",
    signatureImage: "",
    exchangeToCreditDetails: [],
    crnRequestDetails: [],
    refundNote: {
      documentNo: "",
      creditNoteAmount: 0,
      processingFees: 0,
      customerEntitledAmount: 0
    },
    requestedOn: "",
    companyNumber: ""
  }
  const [localLoading, setLocalLoading] = useState(false);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [showSignaturePad, setShowSignaturePad] = useState(true);
  const blnPermissionCreateCreditRedemptionNoteRequest = checkPermission([PermissionConstants.CreateCreditRedemptionNoteRequest]);
  const blnPermissionReviewCreditRedemptionNoteRequest = checkPermission([PermissionConstants.ReviewCreditRedemptionNoteRequest]);
  const blnPermissionManageExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: crnRequestDetail || {
      id: "",
      signatureImage: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchCreditRedemptionNoteRequestDetailAPI(blnCallEmployee = false) {
    setLoading(true);
    if (id) {
      let aryAPICreditRedemptionNoteRequest: any = [
        getCRNRequestWithId(id),
      ];
      await Promise.all(aryAPICreditRedemptionNoteRequest);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCreditRedemptionNoteRequest], true)) {
      return;
    }

    fetchCreditRedemptionNoteRequestDetailAPI(true);
    
    return (() => {
      if (id) {
        setCRNRequestDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");

        if (showSignaturePad && !blnPendingStatus) {
          setShowSignaturePad(false);
        }
        history.push(`/${RoutesList.creditRedemptionNoteRequest}/view/${id}`);
        fetchCreditRedemptionNoteRequestDetailAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const blnPendingStatus = crnRequestFullDetail.status === Constants.pending;
  const blnPendingApprovalStatus = crnRequestFullDetail.status === Constants.pendingApproval;

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        {colSpan !== 0 ? <td colSpan={colSpan} /> : undefined}
        <td className="text-end" style={{ fontWeight: 'bold', width: colSpan === 0 ? '70%' : undefined }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const onClickReviewCRNRequest = async (valueAction: string) => {
    setLocalLoading(true);
    await reviewCRNRequest({ id: id, workflowAction: valueAction })
    setLocalLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title margin-bottom-16 ">
                      <Row style={{ alignItems: 'center' }}>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CreditRedemptionNoteRequest" })}
                            </span>
                            <br />
                            <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                              {crnRequestFullDetail.documentNo}
                            </h3>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {crnRequestFullDetail.businessEntityName} {`${crnRequestFullDetail.companyNumber ? `(${crnRequestFullDetail.companyNumber})` : ""}`}
                            </h4>
                            <div className="flex-align-items-center-without-height">
                              <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {crnRequestFullDetail.branchName} {`(${crnRequestFullDetail.subBrandName})`}
                              </h4>
                            </div>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                               {moment(crnRequestFullDetail.requestedOn).format(Constants.displayDateFormat)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                               {moment(crnRequestFullDetail.requestedOn).format(Constants.displayTimeFormat)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {intl.formatMessage({ id: "Customer" })}:                             {
                              blnPermissionViewCustomer ?
                                <Link to={crnRequestFullDetail.customerId ? `/${RoutesList.customer}/view/${crnRequestFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{crnRequestFullDetail.customerName || Constants.emptyData}</Label>
                                </Link>
                              :
                                `${crnRequestFullDetail.customerName}`
                              }
                            </h4>
                          </div>
                        </Col>
                        <Col xs="6" className="text-end text-muted">
                          <address>
                            {
                              crnRequestFullDetail.consultants &&
                              crnRequestFullDetail.consultants.length > 0 &&
                              <>
                                &nbsp;&nbsp;
                                <strong className="margin-top-16 margin-bottom-2 font-size-16 ">{intl.formatMessage({ id: "Consultant" })}:</strong>
                                {
                                  crnRequestFullDetail.consultants.map((value, index) => (
                                    <div key={`${value}_${index}`}>
                                      <span className="margin-bottom-0 font-weight-bold" style={{ fontSize: '15px', marginTop: '5px' }}>
                                        <>{index + 1}. {value}</>
                                      </span>
                                      {index !== crnRequestFullDetail.consultants.length - 1 && <br />}
                                    </div>
                                  ))
                                }
                              </>
                            }
                          </address>
                        </Col>
                      </Row>
                    </div>
                    <Borderline/>
                    <Row>
                      <Col xs="6">
                        <address className="font-size-14">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div className="" style={{ marginTop: '8px' }}>
                            <GeneralStatus
                              status={crnRequestFullDetail.status}
                              statusColorFunction={creditRedemptionNoteStatusColor}
                              blnDisableCenter />
                          </div>
                        </address>
                      </Col>
                    </Row>
                    {
                      crnRequestFullDetail.exchangeToCreditDetails.length > 0
                      &&
                      <>
                        <div className="py-2 mt-4">
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "ExchangedFrom" })}
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead className="table-light">
                              <tr>
                                <th>{intl.formatMessage({ id: "Name" })}</th>
                                <th className={"text-center"}>{intl.formatMessage({ id: "Type" })}</th>
                                <th>{intl.formatMessage({ id: "ReferenceNo" })}</th>
                                <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                crnRequestFullDetail.exchangeToCreditDetails.map((valueExchangeToCreditDetailTemp, key) => (
                                  <tr key={key}>
                                    <td>{getPurchaseTypeIcon(valueExchangeToCreditDetailTemp.purchaseType)}{valueExchangeToCreditDetailTemp.purchaseName}</td>
                                    <td className={"text-center"}>{valueExchangeToCreditDetailTemp.purchaseType}</td>
                                    <td>
                                      {
                                        blnPermissionManageExchangeToCredit
                                        ?
                                        <Link to={`/${RoutesList.exchangeToCredit}/view/${valueExchangeToCreditDetailTemp.referenceId}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
                                          {valueExchangeToCreditDetailTemp.referenceNo}
                                        </Link>
                                        :
                                        valueExchangeToCreditDetailTemp.referenceNo
                                      }
                                    </td>
                                    <td className="text-end">{returnPriceWithCurrency(valueExchangeToCreditDetailTemp.amount)}</td>
                                  </tr>
                                ))
                              }
                              <>
                                {displayCustomRowData(2, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(crnRequestFullDetail.exchangedCreditAmount))}
                              </>
                            </tbody>
                          </Table>
                        </div>
                      </>
                    }                    
                    <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "RedemptionSummary" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Name" })}</th>
                            <th className={"text-center"}>{intl.formatMessage({ id: "Type" })}</th>
                            <th className={"text-center"}>{intl.formatMessage({ id: "Quantity" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "UnitPrice" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "DiscountedPrice" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "Tax" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "Total" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            crnRequestFullDetail.crnRequestDetails.map((valueCRNRequestDetailTemp, key) => (
                              <tr key={key}>
                                <td>{getPurchaseTypeIcon(valueCRNRequestDetailTemp.purchaseType)}{valueCRNRequestDetailTemp.purchaseName}</td>
                                <td className={"text-center"}>{valueCRNRequestDetailTemp.purchaseType}</td>
                                <td className={"text-center"}>{valueCRNRequestDetailTemp.quantity}</td>
                                <td className="text-center">{returnPriceWithCurrency(valueCRNRequestDetailTemp.originalPrice)}</td>
                                <td className="text-center">{returnPriceWithCurrency(valueCRNRequestDetailTemp.discountAmount)}</td>
                                <td className="text-center">{returnPriceWithCurrency(valueCRNRequestDetailTemp.taxAmount)}</td>
                                <td className="text-end">{returnPriceWithCurrency(valueCRNRequestDetailTemp.totalPrice)}</td>
                              </tr>
                            ))
                          }
                          <>
                            <tr>
                              <td colSpan={5} />
                              <td className="text-end" style={{ fontWeight: 'bold', minWidth: '150px' }}>
                                <div>
                                  {`${intl.formatMessage({ id: "SubTotal" })}`}
                                </div>
                                <div>
                                  {`${intl.formatMessage({ id: "SubTotalDiscount" })}`}
                                </div>
                                <div>
                                  {`${intl.formatMessage({ id: "SubTotalTax" })}`}
                                </div>
                                {
                                  Constants.supportMalaysiaRounding
                                  &&
                                  <div>
                                    {`${intl.formatMessage({ id: "Rounding" })}`}
                                  </div>
                                }
                              </td>
                              <td className="text-end" style={{ minWidth: '150px' }}>
                                <div>
                                  {`${returnPriceWithCurrency(crnRequestFullDetail.subTotalAmount)}`}
                                </div>
                                <div>
                                  {`${returnPriceWithCurrency(crnRequestFullDetail.discountAmount)}`}
                                </div>
                                <div>
                                  {`${returnPriceWithCurrency(crnRequestFullDetail.taxAmount)}`}
                                </div>
                                {
                                  Constants.supportMalaysiaRounding
                                  &&
                                  <div>
                                    {`${returnPriceWithCurrency(crnRequestFullDetail.roundingAmount)}`}
                                  </div>
                                }
                              </td>
                            </tr>
                            {displayCustomRowData(5, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(crnRequestFullDetail.totalAmount))}
                          </>
                        </tbody>
                      </Table>
                    </div>
                    {
                      crnRequestFullDetail.refundNote
                      &&
                      <>
                        <div className="py-2 mt-3">
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "Refund" })}
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-fixed" style={{ width: '100%' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{""}</th>
                                <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key={`refund_document_key`}>
                                <td>{`${intl.formatMessage({ id: "RefundToCustomer" })}`}</td>
                                <td className="text-end">{returnPriceWithCurrency(crnRequestFullDetail.refundNote.creditNoteAmount)}</td>
                              </tr>
                              <tr>
                                <td className="text-end" style={{ fontWeight: "bold", minWidth: "80%", width: "80%" }}>
                                  <div>
                                    {`${intl.formatMessage({ id: "ProcessingFees" })}`}
                                  </div>
                                  <div>
                                    {`${intl.formatMessage({ id: "RefundAmount" })}`}
                                  </div>
                                </td>
                                <td className="text-end" style={{ minWidth: '150px' }}>
                                  <div>
                                    {`${returnPriceWithCurrency(crnRequestFullDetail.refundNote.processingFees)}`}
                                  </div>
                                  <div>
                                    {`${returnPriceWithCurrency(crnRequestFullDetail.refundNote.customerEntitledAmount)}`}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </>
                    }
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Note" })} :
                      </h3>
                    </div>
                    <GeneralTextAreaForModal
                      title=""
                      name="note"
                      className="margin-top-0"
                      row={5}
                      disabled={true}
                      field={crnRequestFullDetail}
                      setFieldValue={() => { }} />
                    <SignaturePad
                      title={intl.formatMessage({ id: "CustomerSignature" })}
                      successMessage={successMessage}
                      validation={validation}
                      disabled={Boolean(crnRequestFullDetail.signatureImage) || Boolean(successMessage) || (!blnPermissionCreateCreditRedemptionNoteRequest && !Boolean(crnRequestFullDetail.signatureImage))}
                      isSubmitting={localLoading} />
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        !crnRequestFullDetail.signatureImage &&
                        showSignaturePad &&
                        blnPermissionCreateCreditRedemptionNoteRequest &&
                        (!Boolean(successMessage)) && 
                        <MyButton
                          type="button"
                          class="btn btn-info"
                          content={intl.formatMessage({ id: "Sign" }).toUpperCase()}
                          disable={localLoading || Boolean(successMessage) || !validation.values.signatureImage}
                          loading={localLoading}
                          onClick={async () => {
                            const valuesTemp: any = _.cloneDeep(validation.values);
                            if (!valuesTemp.signatureImage) {
                              validation.setFieldValue("signatureError", true)
                            }
                            else {
                              let valuesFinal = {
                                signatureImage: valuesTemp.signatureImage,
                                id: valuesTemp.id
                              }
                              try {
                                setLocalLoading(true);
                                await signCRNRequest(valuesFinal);
                              } finally {
                                setLocalLoading(false);
                              }
                            }
                          }}
                        />
                      }
                      {
                        blnPendingApprovalStatus
                        &&
                        blnPermissionReviewCreditRedemptionNoteRequest
                        &&
                        <>
                          <MyButton
                            type="button"
                            class="btn btn-success margin-right-8"
                            content={intl.formatMessage({ id: "Approve" })}
                            onClick={() => {
                              onClickReviewCRNRequest(Constants.approve);
                            }}
                            disable={localLoading || Boolean(successMessage)}
                            loading={localLoading}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-danger"
                            content={intl.formatMessage({ id: "Reject" })}
                            onClick={() => {
                              onClickReviewCRNRequest(Constants.reject);
                            }}
                            disable={localLoading || Boolean(successMessage)}
                            loading={localLoading}
                          />
                        </>
                      }
                      {
                        !successMessage &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={true}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* {
              <Row className="">
                <div>
                  <Col className="standard-layout">
                    <h5 className="mb-4">{intl.formatMessage({ id: "ReferenceDocuments" })}</h5>
                    <div className="margin-top--16">
                      <PaginationTable
                        title={""}
                        currentPage={currentPageProductRedemption}
                        setCurrentPage={setCurrentPageProductRedemption}
                        pageSize={productRedemptionPageSize}
                        totalItem={customerSalesOrderProductRedemptionTotalItems}
                        tableColumns={ProductRedemptionColumns}
                        data={customerSalesOrderProductRedemptionList}
                        localLoading={loadingProductRedemption}
                        setLocalLoading={setLoadingProductRedemption}
                        emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ProductRedemptions" }) })}
                        objSorted={[]}
                        hideSearchBar={true}
                        salesOrderId={id}
                        requestAPI={getCustomerSalesOrderProductRedemptionList}
                        keyField={"randomId"} 
                        blnRemoveBackground={true}/>
                    </div>
                  </Col>
                </div>
              </Row>
            } */}
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CreditRedemptionNoteRequestDetail);
