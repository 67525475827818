import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, exchangeToCreditStatusColor, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import Loading from "../../app/components/loading/Loading";
import { history } from "../..";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";

const ReturnRedemption = () => {
  const intl = useIntl();

  //Use Store
  const { returnRedemptionStore, customerStore, staticReferenceStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { returnRedemptionList, returnRedemptionPaginationParams, getReturnRedemption, setReturnRedemptionList, setReturnRedemptionPaginationParams } = returnRedemptionStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedReturnRedemptionStatus, setSelectedReturnRedemptionStatus] = useState<string>(AllStatus);
  const [selectedReturnRedemptionStatusId, setSelectedReturnRedemptionStatusId] = useState<number | string | undefined>(" ");
  const [returnRedemptionStatusList, setReturnRedemptionStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  // const blnPermissionManageCustomerSalesOrder =  checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
      referenceDocumentNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  //Default Sorting Type
  const defaultSorted = [];

  //Table Content UI
  const ReturnRedemptionColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
    },
    {
      dataField: "issueDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.issueDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.issueDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CreditRedemptionNoteNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          // blnPermissionManageCustomerSalesOrder 
          // ?
          //   <Link to={row.salesOrderId ? `/${RoutesList.creditRedemptionNote}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
          //     <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.salesOrderNo}</Label>
          //   </Link>
          // :
            row.salesOrderNo
        }
      </>
    },
    {
      dataField: "totalExchangeAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.totalExchangeAmount)}</>, //Custom UI to display
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={exchangeToCreditStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.returnRedemption}/view/${row.id}`}
              type={'view'} />
          </div>
        </>
      ),
    },
  ];

  let returnRedemptionRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    status: selectedReturnRedemptionStatusId,
    statusName: selectedReturnRedemptionStatus,
    documentNo: validation.values.documentNo,
    referenceDocumentNo: validation.values.referenceDocumentNo
  }

  useEffect(() => {
    async function fetchReturnRedemptionListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.returnRedemption)
      if (blnBackNavigation) {
        if (returnRedemptionPaginationParams) {
          setCurrentPage(returnRedemptionPaginationParams.pageNumber);
          setSelectedBranchId(returnRedemptionPaginationParams.branchId!);
          setSelectedBranchName(returnRedemptionPaginationParams.branchName!);
          setSelectedReturnRedemptionStatusId(returnRedemptionPaginationParams.status)
          setSelectedReturnRedemptionStatus(returnRedemptionPaginationParams.statusName || AllStatus)
          setSelectedCustomerId(returnRedemptionPaginationParams.customerId || "")
          setSelectedCustomer(returnRedemptionPaginationParams.customerName || "")
          validation.setValues((values) => ({
            ...values,
            documentNo: returnRedemptionPaginationParams.documentNo || "",
            referenceDocumentNo: returnRedemptionPaginationParams.referenceDocumentNo || "",
          }))
          returnRedemptionRequestBody = returnRedemptionPaginationParams;
        }
      }
      else {
        setReturnRedemptionPaginationParams(undefined);
      }
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.exchangeToCreditStatus, AllStatus),
        getBranchDropDown(),
        getReturnRedemption(returnRedemptionRequestBody)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setReturnRedemptionStatusList(resultAPI[0]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageExchangeToCredit], true)) {
      return;
    }

    fetchReturnRedemptionListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.returnRedemption)
        if (!blnSetParams) {
          setReturnRedemptionPaginationParams(undefined);
        }
      }
      setReturnRedemptionList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchReturnRedemptionAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  useEffect(() => {
    if (
      (selectedReturnRedemptionStatusId || selectedReturnRedemptionStatusId === 0) && !initialCallAPI
    ) {
      fetchReturnRedemptionAPI(true);
    }
  }, [selectedReturnRedemptionStatusId, selectedCustomerId, triggerSearch]);

  async function fetchReturnRedemptionAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getReturnRedemption({
      ...returnRedemptionRequestBody,
      ...customerId === Constants.emptyData && { customerId: "", customerName: "" },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ReturnRedemption" }) })}
        title={intl.formatMessage({ id: "ReturnRedemption" })}
        breadCrumbList={breadCrumbList}
      >
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ReturnRedemption" }) })}
                name="returnRedemptionStatus"
                options={returnRedemptionStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedReturnRedemptionStatus}
                initialValue={selectedReturnRedemptionStatusId}
                setFieldLabel={setSelectedReturnRedemptionStatus}
                setFieldValue={setSelectedReturnRedemptionStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row className={`${loadingCustomer ? "margin-bottom-16" : ""}`}>
            <DoubleColumnRowParent>
              {
                displayCustomerDropdown &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "DocumentNo" })}
                name="documentNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "CreditRedemptionNoteNo" })}
                name="referenceDocumentNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterCreditRedemptionNoteNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
          ? 
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ReturnRedemptionColumns}
            data={returnRedemptionList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ReturnRedemption" }) })}
            objSorted={defaultSorted}
            requestAPI={getReturnRedemption}
            customerId={selectedCustomerId}
            customerName={selectedCustomer}
            branchId={selectedBranchId}
            branchName={selectedBranchName}
            status={selectedReturnRedemptionStatusId}
            statusName={selectedReturnRedemptionStatus}
            documentNo={validation.values.documentNo}
            referenceDocumentNo={validation.values.referenceDocumentNo} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ReturnRedemption);
