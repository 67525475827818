import {
  EInvoiceCodeListObject,
  TaxEntityAddObject,
  TaxEntityDetailObject,
  TaxEntityListObject,
  TaxEntityUpdateObject,
  TaxEntityWebFormAddObject,
  TaxEntityWebFormRequestObject,
  ValidateTaxEntityWebFormObject,
} from "../models/taxEntity";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import { Constants } from "../constants/Constants";

export default class TaxEntityStore {
  taxEntityList: TaxEntityListObject[] = [];
  taxEntityDetail: TaxEntityDetailObject | undefined = undefined;
  eInvoiceCodeList: EInvoiceCodeListObject[] = [];
  taxEntityValidateDetail: ValidateTaxEntityWebFormObject | undefined = undefined;
  customerTaxEntityTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.taxEntityList = [];
    this.taxEntityDetail = undefined;
    this.eInvoiceCodeList = [];
    this.taxEntityValidateDetail = undefined;
    this.customerTaxEntityTotalItems = 0;
  };

  setTaxEntityList = (taxEntityList: TaxEntityListObject[]) => {
    this.taxEntityList = taxEntityList;
  };

  setTaxEntityDetail = (taxEntityDetail: TaxEntityDetailObject | undefined) => {
    this.taxEntityDetail = taxEntityDetail;
  };

  setEInvoiceCodeList = (eInvoiceCodeList: EInvoiceCodeListObject[]) => {
    this.eInvoiceCodeList = eInvoiceCodeList;
  }

  getCustomerTaxEntityWithId = async (customerId: string) => {
    try {
      const resultCustomerTaxEntityList =
        await agent.TaxEntity.getCustomerTaxEntityList(customerId);
      runInAction(() => {
        this.taxEntityList = resultCustomerTaxEntityList;
        this.customerTaxEntityTotalItems = resultCustomerTaxEntityList.length
      });
      return Promise.resolve(resultCustomerTaxEntityList);
    } catch (error: any) {
      console.log(error);
      store.commonStore.setErrorMessage(
        Array.isArray(error) ? error[0] : error.message
      );
      this.taxEntityList = [];
    }
  };

  getTaxEntityWithId = async (id: string) => {
    try {
      const resultTaxEntityDetail = await agent.TaxEntity.taxEntitiyDetail(id);
      runInAction(() => {
        this.taxEntityDetail = resultTaxEntityDetail;
      });
      return Promise.resolve(resultTaxEntityDetail);
    } catch (error: any) {
      console.log(error);
      store.commonStore.setErrorMessage(
        Array.isArray(error) ? error[0] : error.message
      );
      this.taxEntityDetail = undefined;
    }
  };

  getEInvoiceCodeWithType = async (codeType: string) => {
    try {
      const resultEInvoiceCodeList = await agent.TaxEntity.getEInvCodeList(codeType);
      runInAction(() => {
        this.eInvoiceCodeList = resultEInvoiceCodeList;
      })
      return Promise.resolve(resultEInvoiceCodeList);
    } catch (error: any) {
      console.log(error);
      store.commonStore.setErrorMessage(
        Array.isArray(error) ? error[0] : error.message
      );
      this.eInvoiceCodeList = [];
    }
  }

  getCustomerTaxEntityDropdown = async (customerId: string) => {
    let objNoTin = {customerId: "", tinDisplayName: Constants.noTin, tinId: Constants.noTinValue};
    try {
      const resultCustomerTaxEntityDropdownList = await agent.TaxEntity.getCustomerTaxEntityDropdownList(customerId);
      resultCustomerTaxEntityDropdownList.unshift(objNoTin);
      return Promise.resolve(resultCustomerTaxEntityDropdownList);
    } catch (error: any) {
      console.log(error);
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message);
      return Promise.resolve([objNoTin]);
    }
  };

  addTaxEntity = async (taxEntityRequestBody: TaxEntityAddObject) => {
    try {
      await agent.TaxEntity.addTaxEntity(taxEntityRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`TaxEntityAddSuccess`)
      return Promise.resolve({status: Constants.success});
    } catch (error : any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateTaxEntity = async (taxEntityRequestBody: TaxEntityUpdateObject) => {
    try {
      await agent.TaxEntity.updateTaxEntity(taxEntityRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`TaxEntityUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    } catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteTaxEntity = async (id: string ) => {
    try {
      await agent.TaxEntity.deleteTaxEntity(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`TaxEntityDeleteSuccess`)
      return Promise.resolve({status: Constants.success});
    } catch (error: any) {
        console.error(error)
        store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
        return Promise.resolve({status: Constants.failed});
    }
  }

  addTaxEntityFormHistory = async (taxEntityRequestBody: TaxEntityWebFormRequestObject) => {
    try {
      const resultTaxEntityDetail =  await agent.TaxEntity.addTaxEntityWebFormHistory(taxEntityRequestBody)
      return Promise.resolve({status: Constants.success, data: resultTaxEntityDetail});
    } catch (error:any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  addTaxEntityWithWebForm = async (taxEntityRequestBody: TaxEntityWebFormAddObject) => {
    try {
      await agent.TaxEntity.addTaxEntityWithWebForm(taxEntityRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`TaxEntityAddSuccess`)
      return Promise.resolve({status: Constants.success});
    } catch (error : any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  validateTaxEntityWebForm = async (id: string) => {
    try {
      const resultValidateWebForm = await agent.TaxEntity.validateTaxEntity(id);
      runInAction(() => {
        this.taxEntityValidateDetail = resultValidateWebForm;
      })
      return Promise.resolve({status: Constants.success, data: resultValidateWebForm.customerName});
    } catch (error: any) {
      return Promise.resolve({status: Constants.failed, data: error.message});
    }
  }
}