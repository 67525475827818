import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, displayPromotionCode, displayWatermark } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "../..";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import SignaturePad from "../../app/components/form/SignaturePad";
import Borderline from "../../app/components/form/BorderLine";
import { burgerMenu } from "../../app/models/common";
import parse from 'html-react-parser';

const InvoiceDetailFromSalesOrder = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { invoiceStore, commonStore, tncStore } = useStore();
  const { successMessage, hideComponentForPrint, loading, setLoading } = commonStore;
  const { invoiceDetailFromSalesOrder, setInvoiceDetailFromSalesOrder, getInvoiceFromCustomerSalesOrderWithId } = invoiceStore;
  const { tncText, getTncWithCategory } = tncStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Invoice" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Invoice" }), urlPath: RoutesList.invoice }];
  const invoiceFullDetail = invoiceDetailFromSalesOrder || {
    id: "",
    customerInvoiceNo: "",
    branchName: "",
    branchId: "",
    customerName: "",
    customerId: "",
    customerBranchId: "",
    customerBranchName: "",
    businessEntityName: "",
    companyNumber: "",
    consultantIds: [],
    consultants: [],
    status: "",
    subTotalAmount: 0,
    taxAmount: 0,
    discountAmount: 0,
    totalAmount: 0,
    totalPayment: 0,
    balance: 0,
    deposit: 0,
    rounding: 0,
    totalRefundAmount: 0,
    note: "",
    isPaymentAdjustmentAllowed: false,
    isUnderPaymentAdjustment: false,
    isVoidAllowed: false,
    issueDate: "",
    submissionDate: "",
    signatureImage: "",
    voidDate: "",
    reviewedOn: "",
    reviewerUserName: "",
    isAllowRegenerateProductDO: false,
    isAccessOwnBranchCustomer: false,
    hasActiveCustomerAccess: false,
    isEditConsultantAllowed: false,
    voidedSalesOrderId: "",
    voidedSalesOrderNo: "",
    salesOrderDetails: [{
      id: "",
      purchaseType: "",
      count: 0,
      itemId: "",
      itemName: "",
      itemDesc: "",
      discountType: "",
      discountValue: 0,
      expiredDate: "",
      unitPrice: 0,
      taxRate: 0,
      taxAmount: 0,
      taxedPrice: 0,
      discountedPrice: 0,
      discountAmount: 0,
      totalPrice: 0,
      remark: "",
      serviceById: "",
      serviceProductUsedIds: [],
      serviceProductUsed: [],
      additionalData: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
      additionalFormData: [{
        id: "",
        name: "",
        controlType: "",
        rows: 0,
        options: [],
        placeHolder: "",
        isRequired: false,
        isActive: false,
        displayOrder: 0,
        value: "",
      }]
    }],
    paymentHistories: [{
      id: "",
      paymentDate: "",
      paymentMethodName: "",
      optionName: "",
      paidAmount: 0,
      last4CardDigit: 0,
      cardType: "",
      transactionNo: "",
    }],
    customerDeliverOrders: [{
      redeemType: "",
      performUserId: "",
      performUserName: "",
      packageId: "",
      packageName: "",
      redeemDetailId: "",
      redeemDetailName: "",
      count: 0,
      description: "",
    }],
    totalInvoiceAmount: 0,
    totalCreditPayment: 0,
    subBrandName: "",
    purchaseBranchId: "",
    purchaseBranchName: "",
    tinDisplayName: ""
  }
  const blnHQStaff = !getBranchUser();
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer])
  const [viewType, setViewType] = useState(blnHQStaff ? Constants.fullVersion : Constants.customerVersion);
  const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
  const totalPaymentWithoutCredit = invoiceFullDetail.totalPayment - invoiceFullDetail.totalCreditPayment;
  const [displayRedeemCreditView, setDisplayRedeemCreditView] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: invoiceDetailFromSalesOrder || {
      id: "",
      consultantIds: [],
      consultants: [],
      signatureImage: "",
      cartId: "",
      reviewRemark: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchInvoiceDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getInvoiceFromCustomerSalesOrderWithId(id),
      ];
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        const dateOnly = moment(resultAPI[0].submissionDate).format(Constants.displayDateFormat)
        await getTncWithCategory(Constants.invoice, dateOnly)
      } 
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerInvoice], true)) {
      return;
    }

    fetchInvoiceDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setInvoiceDetailFromSalesOrder(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if(invoiceDetailFromSalesOrder){
      let indexViewSalesOrder = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerSalesOrder" })}`})
      if (indexViewSalesOrder < 0 && blnPermissionManageCustomerSalesOrder) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerSalesOrder" })}`, 
          onFunction: () => { 
            history.push(`/${RoutesList.customerSalesOrder}/view/${id}`)
          },
          blnSubView: false
        })
      }
      
      let indexViewAs = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewAs" })}`})
      if (indexViewAs < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewAs" })}`, 
          onFunction: () => {},
          blnSubTitle: true
        })
      }
      
      let indexViewFullVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewFullVersion" })}`})
      if (indexViewFullVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewFullVersion" })}`, 
          onFunction: () => {
            setViewType(Constants.fullVersion);
          },
          blnSubView: true
        })
      }

      let indexViewCustomerVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerVersion" })}`})
      if (indexViewCustomerVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.customerVersion)
          },
          blnSubView: true
        })
      }
      
      let indexViewCustomerWithoutQuantityVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerWithoutQuantityVersion" })}`})
      if (indexViewCustomerWithoutQuantityVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerWithoutQuantityVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.customerWithoutQuantityVersion)
          },
          blnSubView: true
        })
      }      

      let indexViewRedeemCreditVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewRedeemCreditVersion" })}`})
      if (indexViewRedeemCreditVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewRedeemCreditVersion" })}`, 
          onFunction: () => { 
            setDisplayRedeemCreditView((valueDisplayRedeemCredit)=> !valueDisplayRedeemCredit);
          },
          blnSubView: true
        })
      }

      //Resubmit E-Invoice Request button
      // let indexResubmitEInvoiceRequest = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ResubmitEInvoiceRequest" })}`})
      // if (true && indexResubmitEInvoiceRequest < 0) {
      //   burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ResubmitEInvoiceRequest" })}`, 
      //     onFunction: () => { 
            
      //     }
      //   })
      // }
      // else if (true && indexResubmitEInvoiceRequest > -1) {
      //   burgerMenuListContent.splice(indexResubmitEInvoiceRequest, 1);
      // }
    }
  }, [invoiceDetailFromSalesOrder])

  const blnRefunded = invoiceFullDetail.totalRefundAmount > 0;

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        {colSpan !== 0 ? <td colSpan={colSpan} /> : undefined}
        <td className="text-end" style={{ fontWeight: 'bold', width: colSpan === 0 ? '70%' : undefined }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title margin-bottom-16 ">
                      <Row style={{ alignItems: 'center' }}>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "Invoice" })}
                            </span>
                            <br />
                            <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                              {invoiceFullDetail.customerInvoiceNo}
                            </h3>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {invoiceFullDetail.businessEntityName} {invoiceFullDetail.companyNumber ? `(${invoiceFullDetail.companyNumber})` : ""}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {invoiceFullDetail.purchaseBranchName} {invoiceFullDetail.subBrandName ? `(${invoiceFullDetail.subBrandName})` : ""}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                               {moment(invoiceFullDetail.submissionDate).format(Constants.displayDateFormat)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                               {moment(invoiceFullDetail.submissionDate).format(Constants.displayTimeFormat)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {intl.formatMessage({ id: "Customer" })}:                             {
                              blnPermissionViewCustomer ?
                                <Link to={invoiceFullDetail.customerId ? `/${RoutesList.customer}/view/${invoiceFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{invoiceFullDetail.customerName || Constants.emptyData}</Label>
                                </Link>
                              :
                                `${invoiceFullDetail.customerName}`
                              }
                            </h4>
                            {
                              invoiceFullDetail.tinDisplayName
                              &&
                              <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                {`${intl.formatMessage({ id: "Tin" })}: ${invoiceFullDetail.tinDisplayName}`}  
                              </h4>
                            }
                          </div>
                        </Col>
                        <Col xs="6" className="text-end text-muted">
                          <address>
                            {
                              invoiceFullDetail.consultants &&
                              invoiceFullDetail.consultants.length > 0 &&
                              <>
                                <strong className="margin-top-16 margin-bottom-2 font-size-16 ">{intl.formatMessage({ id: "Consultant" })}:</strong>
                                {
                                  invoiceFullDetail.consultants.map((value, index) => (
                                    <div key={`${value}_${index}`}>
                                      <span className="margin-bottom-0 font-weight-bold" style={{ fontSize: '15px', marginTop: '5px' }}>
                                        <>{index + 1}. {value}</>
                                      </span>
                                      {index !== invoiceFullDetail.consultants.length - 1 && <br />}
                                    </div>
                                  ))
                                }
                              </>
                            }
                          </address>
                        </Col>
                      </Row>
                    </div>
                    <Borderline/>
                    {/* watermark */}
                    {displayWatermark()}
                    <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "OrderSummary" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Name" })}</th>
                            <th className={viewType !== Constants.customerWithoutQuantityVersion ? "text-center" : ""}>{intl.formatMessage({ id: "Type" })}</th>
                            {<th className={viewType === Constants.fullVersion ? "text-center" : "text-end"}>{viewType !== Constants.customerWithoutQuantityVersion ? intl.formatMessage({ id: "Quantity" }) : ""}</th>}
                            {
                              viewType === Constants.fullVersion &&
                              <>
                                <th className="text-center">{intl.formatMessage({ id: "UnitPrice" })}</th>
                                <th className="text-center">{intl.formatMessage({ id: "DiscountedPrice" })}</th>
                                <th className="text-center">{intl.formatMessage({ id: "Tax" })}</th>
                                <th className="text-end">{intl.formatMessage({ id: "Total" })}</th>
                              </>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            invoiceFullDetail.salesOrderDetails.map((item, key) => (
                              <tr key={key}>
                                <td>{getPurchaseTypeIcon(item.purchaseType)}{item.itemName} {displayPromotionCode(item.promotionCode)} <i className="text-muted">{(!blnHQStaff && item.status !== Constants.active) && `(${newSpaceBeforeCapitalLetter(item.status)})`}</i></td>
                                <td className={viewType !== Constants.customerWithoutQuantityVersion ? "text-center" : ""}>{item.purchaseType}</td>
                                {<td className={viewType === Constants.fullVersion ? "text-center" : "text-end"}>{viewType !== Constants.customerWithoutQuantityVersion ? item.count : ""}</td>}
                                {
                                  viewType === Constants.fullVersion &&
                                  <>
                                    <td className="text-center">{returnPriceWithCurrency(item.originalPrice)}</td>
                                    <td className="text-center">{returnPriceWithCurrency(item.discountAmount)}</td>
                                    <td className="text-center">{returnPriceWithCurrency(item.taxAmount)}</td>
                                    <td className="text-end">{returnPriceWithCurrency(item.totalPrice)}</td>
                                  </>
                                }
                              </tr>
                            ))
                          }
                          {
                            viewType === Constants.fullVersion
                            ?
                            <>
                              <tr>
                                <td colSpan={5} />
                                <td className="text-end" style={{ fontWeight: 'bold', minWidth: '150px' }}>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotal" })}`}
                                  </div>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotalDiscount" })}`}
                                  </div>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotalTax" })}`}
                                  </div>
                                  {
                                    Constants.supportMalaysiaRounding
                                    &&
                                    <div>
                                      {`${intl.formatMessage({ id: "Rounding" })}`}
                                    </div>
                                  }
                                  {
                                    invoiceFullDetail.totalCreditPayment
                                    &&
                                    displayRedeemCreditView
                                    ?
                                    <div>
                                      {`${intl.formatMessage({ id: "RedeemedCredit" })}`}
                                    </div>
                                    :
                                    null
                                  }
                                </td>
                                <td className="text-end" style={{ minWidth: '150px' }}>
                                  <div>
                                    {`${returnPriceWithCurrency(invoiceFullDetail.subTotalAmount)}`}
                                  </div>
                                  <div>
                                    {`${returnPriceWithCurrency(invoiceFullDetail.discountAmount)}`}
                                  </div>
                                  <div>
                                    {`${returnPriceWithCurrency(invoiceFullDetail.taxAmount)}`}
                                  </div>
                                  {
                                    Constants.supportMalaysiaRounding
                                    &&
                                    <div>
                                      {`${returnPriceWithCurrency(invoiceFullDetail.rounding)}`}
                                    </div>
                                  }
                                  {
                                    invoiceFullDetail.totalCreditPayment
                                    &&
                                    displayRedeemCreditView
                                    ?
                                    <div>
                                      {`-${returnPriceWithCurrency(invoiceFullDetail.totalCreditPayment)}`}
                                    </div>
                                    :
                                    null
                                  }
                                </td>
                              </tr>
                              {displayCustomRowData(5, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(!displayRedeemCreditView ? invoiceFullDetail.totalAmount : invoiceFullDetail.totalInvoiceAmount))}
                            </>
                            :
                            <>
                              {
                                invoiceFullDetail.totalCreditPayment
                                &&
                                displayRedeemCreditView
                                ?
                                <>
                                  {displayCustomRowData(1, `${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(invoiceFullDetail.totalAmount))}
                                  {displayCustomRowData(1, `${intl.formatMessage({ id: "RedeemedCredit" })}`, `-${returnPriceWithCurrency(invoiceFullDetail.totalCreditPayment)}`)}
                                </>
                                :
                                null
                              }
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(!displayRedeemCreditView ? invoiceFullDetail.totalAmount : invoiceFullDetail.totalInvoiceAmount))}
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Paid" })}`, blnRefunded && invoiceFullDetail.status == Constants.partial ? returnPriceWithCurrency(invoiceFullDetail.totalRefundAmount) : returnPriceWithCurrency(!displayRedeemCreditView ? invoiceFullDetail.totalPayment : totalPaymentWithoutCredit))}
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Balance" })}`, returnPriceWithCurrency(invoiceFullDetail.balance))}
                              {
                                blnRefunded &&
                                <>
                                  {displayCustomRowData(1, `${intl.formatMessage({ id: "RefundAmount" })}`, returnPriceWithCurrency(invoiceFullDetail.totalRefundAmount))}
                                </>
                              }
                            </>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "PaymentHistory" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed" style={{ width: '100%' }}>
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Date" })}</th>
                            <th>{intl.formatMessage({ id: "Time" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentBranch" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "TransactionNo" })}/{intl.formatMessage({ id: "ApprovalCode" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                            {!hideComponentForPrint && <th/>}
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceFullDetail.paymentHistories && invoiceFullDetail.paymentHistories.length > 0 &&
                            invoiceFullDetail.paymentHistories.map((item, key) => (
                              displayRedeemCreditView && item.isCreditPayment
                              ?
                              null
                              :
                              <tr key={key}>
                                <td>{moment(item.paymentDate).format(Constants.displayDateFormat)}</td>
                                <td>{moment(item.paymentDate).format(Constants.displayTimeFormat)}</td>
                                <td>{item.branchName || Constants.emptyData}</td>
                                <td>
                                  <p className={`margin-bottom-0`}>{`${item.paymentMethodName}${item.cardType ? ` (${item.cardType} | ${item.last4CardDigitNo})` : ""}`}</p>
                                  <p className="payment-method-sub-text">{`${item?.optionName ? `${item.optionName}` : ""}${item.paymentIssuerBankName ? ` - ${item.paymentIssuerBankName}` : ""}`}</p>
                                </td>
                                <td className="text-center">{item.transactionNo || Constants.emptyData}</td>
                                <td className="text-end">{returnPriceWithCurrency(item.paidAmount)}</td>
                                {
                                  !hideComponentForPrint
                                  &&
                                  <td className="text-end">
                                    <Link
                                      to={`/${RoutesList.receipt}/view/${item.id}`}
                                      className="text-info"
                                      target={Constants.blank} rel="noopener noreferrer">
                                      <p><CashCheckIcon size={20} /> {`${intl.formatMessage({ id: "ViewDetails" })}`}</p>
                                    </Link>
                                  </td>
                                }
                              </tr>
                            ))
                          }
                          {
                            <>
                              {displayCustomRowData(hideComponentForPrint ? 4 : 5, `${intl.formatMessage({ id: "Paid" })}`, returnPriceWithCurrency(!displayRedeemCreditView ? invoiceFullDetail.totalPayment : totalPaymentWithoutCredit))}
                              {displayCustomRowData(hideComponentForPrint ? 4 : 5, `${intl.formatMessage({ id: "Balance" })}`, returnPriceWithCurrency(invoiceFullDetail.balance))}
                              {
                                blnRefunded &&
                                displayCustomRowData(hideComponentForPrint ? 4 : 5, `${intl.formatMessage({ id: "TotalExchangedAmount" })}`, returnPriceWithCurrency(invoiceFullDetail.totalRefundAmount))
                              }
                            </>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Note" })} :
                      </h3>
                    </div>
                    <GeneralTextAreaForModal
                      title=""
                      name="note"
                      className="margin-top-0"
                      row={5}
                      disabled={true}
                      field={invoiceFullDetail}
                      setFieldValue={() => { }} />
                    <div className="text-center margin-top-16" style={{ fontSize: '14px', pageBreakInside: "avoid"}}>{tncText?.description ? (parse(tncText.description)) : ""}</div>
                    <SignaturePad
                      title={intl.formatMessage({ id: "CustomerSignature" })}
                      successMessage={successMessage}
                      validation={validation}
                      disabled={Boolean(invoiceFullDetail.signatureImage)}
                      isSubmitting={false} />
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={true}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(InvoiceDetailFromSalesOrder);
