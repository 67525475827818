import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import _ from "lodash";
import { CreditRedemptionRequestBody, CRNRequestDetailObject, CRNRequestListObject, CRNRequestReviewRequestBody, CRNRequestSignRequestBody, TopUpCreditRequestBody } from "../models/crnRequest";
import { Constants } from "../constants/Constants";
import { PaginationRequestBody } from "../models/pagination";

export default class CRNRequestStore {
  crnRequestList: CRNRequestListObject[] = [];
  crnRequestDetail: CRNRequestDetailObject | undefined = undefined;
  crnRequestPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.crnRequestList = [];
    this.crnRequestDetail = undefined;
    this.crnRequestPaginationParams = undefined;
  }

  setCRNRequestDetail = (crnRequestDetail: CRNRequestDetailObject | undefined) => {
    this.crnRequestDetail = crnRequestDetail;
  }

  setCRNRequestPaginationParams = (crnRequestPaginationParams: PaginationRequestBody | undefined) => {
    this.crnRequestPaginationParams = _.cloneDeep(crnRequestPaginationParams);
  }

  getCRNRequest = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      this.setCRNRequestPaginationParams(PaginationRequestBody);
      const resultCRNRequest = await agent.CRNRequest.crnRequestList(PaginationRequestBody);
      runInAction(() => {
        this.crnRequestList = resultCRNRequest.data;
        store.commonStore.setTotalItem(resultCRNRequest.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.crnRequestList = [];
    }
  };

  getCRNRequestWithId = async (id: string) => {
    try{
      const resultCRNRequestDetail = await agent.CRNRequest.crnRequestDetail(id);
      runInAction(() => {
        this.crnRequestDetail = resultCRNRequestDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.crnRequestDetail = undefined;
    }
  }

  signCRNRequest = async (crnRequestSignRequestBody: CRNRequestSignRequestBody) => {
    try{
      let resultSignCRNRequest = await agent.CRNRequest.signCRNRequest(crnRequestSignRequestBody);
      store.commonStore.setSuccessMessage(`CRNRequestSignSuccess`)
      return Promise.resolve({status: Constants.success, data: resultSignCRNRequest});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  reviewCRNRequest = async (reviewCRNRequestRequestBody: CRNRequestReviewRequestBody) => {
    try{
      await agent.CRNRequest.reviewCRNRequest(reviewCRNRequestRequestBody);
      store.commonStore.setSuccessMessage(`CRNRequestReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  topUpCredit = async (crnRequestTopUpRequestBody: TopUpCreditRequestBody) => {
    try{
      const resultTopUpCreditAPI = await agent.CRNRequest.topUpCredit(crnRequestTopUpRequestBody);
      store.commonStore.setSuccessMessage(`TopUpCreditCreateSuccess`)
      return Promise.resolve({status: Constants.success, data: resultTopUpCreditAPI})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: { salesOrderId: "" }})
    }
  }

  submitCreditRedemption = async (creditRedemptionRequestBody: CreditRedemptionRequestBody) => {
    try{
      const resultCreditRedemption = await agent.CRNRequest.submitCreditRedemption(creditRedemptionRequestBody);
      store.commonStore.setSuccessMessage(`CreditRedemptionCreateSuccess`)
      return Promise.resolve({status: Constants.success, data: resultCreditRedemption})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: { creditRedemptionNoteRequestId: "" }})
    }
  }
}
