import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import _ from "lodash";
import { ReturnRedemptionAddObject, ReturnRedemptionDetailObject, ReturnRedemptionGetPreviewRequestBody, ReturnRedemptionListObject, ReturnRedemptionReviewObject } from "../models/returnRedemption";
import { Constants } from "../constants/Constants";
import { PaginationRequestBody } from "../models/pagination";

export default class ReturnRedemptionStore {
  returnRedemptionList: ReturnRedemptionListObject[] = [];
  returnRedemptionDetail: ReturnRedemptionDetailObject | undefined = undefined;
  returnRedemptionPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.returnRedemptionList = [];
    this.returnRedemptionDetail = undefined;
    this.returnRedemptionPaginationParams = undefined;
  } 

  setReturnRedemptionList = (returnRedemptionList: ReturnRedemptionListObject[]) => {
    this.returnRedemptionList = returnRedemptionList;
  }

  setReturnRedemptionDetail = (returnRedemptionDetail: ReturnRedemptionDetailObject | undefined) => {
    this.returnRedemptionDetail = returnRedemptionDetail;
  }

  setReturnRedemptionPaginationParams = (returnRedemptionPaginationParams: PaginationRequestBody | undefined) => {
    this.returnRedemptionPaginationParams = _.cloneDeep(returnRedemptionPaginationParams);
  }

  getReturnRedemption = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      this.setReturnRedemptionPaginationParams(PaginationRequestBody);
      const resultReturnRedemption = await agent.ReturnRedemption.returnRedemptionList(PaginationRequestBody);
      runInAction(() => {
        this.returnRedemptionList = resultReturnRedemption.data;
        store.commonStore.setTotalItem(resultReturnRedemption.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.returnRedemptionList = [];
    }
  };

  getReturnRedemptionWithId = async (id: string) => {
    try{
      const resultReturnRedemptionDetail = await agent.ReturnRedemption.returnRedemptionDetail(id);
      runInAction(() => {
        this.returnRedemptionDetail = resultReturnRedemptionDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.returnRedemptionDetail = undefined;
    }
  }

  addReturnRedemption = async (returnRedemptionRequestBody: ReturnRedemptionAddObject) => {
    try{
      await agent.ReturnRedemption.addReturnRedemption(returnRedemptionRequestBody);
      store.commonStore.setSuccessMessage(`ReturnRedemptionSubmitSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPreviewReturnRedemption = async (returnRedemptionRequestBody: ReturnRedemptionGetPreviewRequestBody) => {
    try{
      const resultPreviewReturnRedemption = await agent.ReturnRedemption.getPreviewReturnRedemption(returnRedemptionRequestBody);
      return Promise.resolve({status: Constants.success, data: resultPreviewReturnRedemption})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: undefined})
    }
  }

  reviewReturnRedemption = async (reviewReturnRedemptionRequestBody: ReturnRedemptionReviewObject) => {
    try{
      await agent.ReturnRedemption.reviewReturnRedemption(reviewReturnRedemptionRequestBody);
      store.commonStore.setSuccessMessage(`ReturnRedemptionReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
