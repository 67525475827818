import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { IncludesLocationPathName, customerTransferRequestStatusColor, newSpaceBeforeCapitalLetter, checkPermission, getBranchId, paymentAdjustmentStatusColor, salesOrderStatusColor, deliveryOrderStatusColor, getBranchUser } from "../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import Borderline from "../../app/components/form/BorderLine";
import SignaturePad from "../../app/components/form/SignaturePad";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import CancelModal from "../../app/components/modal/CancelModal";
import SubBranchRequestModal from "../Customer/Modal/SubBranchRequestModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { EmployeeListObject } from "../../app/models/employee";

const CustomerTransferRequestDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { customerTransferRequestStore, employeeStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
    setShowCancelPrompt
  } = commonStore;
  const { getEmployeeDropdown } = employeeStore;
  const { customerTransferRequestDetail, setCustomerTransferRequestDetail, getCustomerTransferRequestWithId, updateCustomerTransferRequest } = customerTransferRequestStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerTransferRequest" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Requests" }), urlPath: RoutesList.customerTransferRequest }, { title: intl.formatMessage({ id: "CustomerTransferRequest" }), urlPath: RoutesList.customerTransferRequest }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [subBranchRemoveModal, setSubBranchRemoveModal] = useState(false);
  const [subBranchRequestId, setSubBranchRequestId] = useState("");
  const [subBranchRequestAcknowledgementModal, setSubBranchRequestAcknowledgementModal] = useState(false);
  const [blnInvalidBranch, setBlnInvalidBranch] = useState(false);
  const [localEmployeeDropdownList, setLocalEmployeeDropdownList] = useState<EmployeeListObject[]>([]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionViewPaymentAdjustment = checkPermission([PermissionConstants.ManagePaymentAdjustment]);
  const blnPermissionViewCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionUpdateCustomerDeliverOrder = checkPermission([PermissionConstants.UpdateCustomerDeliverOrder]);
  const blnPermissionViewCreditAdjustment = checkPermission([PermissionConstants.ManageCreditAdjustment]);
  const blnPermissionViewExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);
  const blnPermissionViewVirtualWarehouseCreditExchange= checkPermission([PermissionConstants.ManageVirtualWarehouseCreditExchange]);
  const blnPermissionEditVirtualWarehouseCreditExchange= checkPermission([PermissionConstants.ReviewVirtualWarehouseCreditExchange]);
  const blnPermissionViewProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnPermissionEditProductRedemptionNote = checkPermission([PermissionConstants.UpdateProductRedemptionNote]);
  const blnPermissionViewProductRedemptionNoteReturn = checkPermission([PermissionConstants.ManageProductRedemptionNoteReturn]);
  const blnPermissionDeleteCustomerSubBranchRequest = checkPermission([PermissionConstants.UpdateCustomerSubBranchRequest]);
  
  const customerTransferFullDetail = customerTransferRequestDetail || {
    id: "",
    fromBranchId: "",
    fromBranchName: "",
    customerId: "",
    customerNo: "",
    customerName: "",
    contactNo: "",
    identityType: "",
    identityNo: "",
    toBranchId: "",
    toBranchName: "",
    toBranchConsultantId: "",
    toBranchConsultantName: "",
    signatureImage: "",
    signedDate: "",
    reasonOfChange: "",
    status: "",
    approveBy: "",
    approveOn: "",
    reviewComment: "",
    lastVisitDate: "",
    joinedDate: "",
    lastTreatmentDate: "",
    subBranchId: "",
    subBranchName: "",
    customerTransferDocumentNo: ""
  }

  const pendingRequestColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "requestNo",
      text: intl.formatMessage({ id: "RequestNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.pendingRequestType === Constants.paymentAdjustment && blnPermissionViewPaymentAdjustment && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.paymentAdjustment}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.salesOrder && blnPermissionViewCustomerSalesOrder && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.customerSalesOrder}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.deliverOrder && blnPermissionViewCustomerDeliverOrder && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.customerRedemption}/${blnPermissionUpdateCustomerDeliverOrder ? "edit" : "view"}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.creditAdjustment && blnPermissionViewCreditAdjustment && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.creditAdjustment}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.creditExchange && blnPermissionViewExchangeToCredit && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.exchangeToCredit}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.virtualWarehouseCreditExchange && blnPermissionEditVirtualWarehouseCreditExchange && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.virtualWarehouseCreditExchange}/edit/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.virtualWarehouseCreditExchange && blnPermissionViewVirtualWarehouseCreditExchange && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.virtualWarehouseCreditExchange}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.productRedemptionNote && blnPermissionEditProductRedemptionNote && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.productRedemptionNote}/edit/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.productRedemptionNote && blnPermissionViewProductRedemptionNote && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.productRedemptionNote}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.productRedemptionNoteReturn && blnPermissionViewProductRedemptionNoteReturn && blnIsSameOriginalBranch
        ?
        <Link to={`/${RoutesList.productRedemptionNoteReturn}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        `${row.requestNo}`
      )
    },
    {
      dataField: "pendingRequestType",
      text: intl.formatMessage({ id: "PendingRequestType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.pendingRequestType) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "requestStatus",
      text: intl.formatMessage({ id: "RequestStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.requestStatus}
          statusColorFunction={
            row.pendingRequestType === Constants.paymentAdjustment ? paymentAdjustmentStatusColor 
            : 
            row.pendingRequestType === Constants.deliverOrder ? deliveryOrderStatusColor
            :
            salesOrderStatusColor
          }/>
      )
    },
  ];
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerTransferRequestDetail || {
      id: "",
      fromBranchId: "",
      fromBranchName: "",
      customerId: "",
      customerNo: "",
      customerName: "",
      contactNo: "",
      identityType: "",
      identityNo: "",
      toBranchId: "",
      toBranchName: "",
      toBranchConsultantId: "",
      toBranchConsultantName: "",
      signatureImage: "",
      signedDate: "",
      reasonOfChange: "",
      status: "",
      approveBy: "",
      approveOn: "",
      reviewComment: "",
      workflowAction: "",
      pendingRequests: [],
      subBranchId: "",
      subBranchName: '',
      customerTransferDocumentNo: "",
      isReview: false,
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      let valuesFinal = {
        id: valuesTemp.id,
        signatureImage: valuesTemp.signatureImage,
        isReview: valuesTemp.isReview,
        reasonOfChange: valuesTemp.reasonOfChange,
        reviewComment: valuesTemp.reviewComment,
        workflowAction: valuesTemp.workflowAction,
        toBranchConsultantId: valuesTemp.toBranchConsultantId
      }

      if (valuesTemp.workflowAction === Constants.submit || valuesTemp.workflowAction === Constants.cancel) {
        valuesFinal.isReview = false;
      }
      else {
        valuesFinal.isReview = true;
      }
      if (valuesTemp.workflowAction === Constants.submit && !valuesTemp.signatureImage) {
        validation.setFieldValue("signatureError", true)
        setErrorMessage(intl.formatMessage({ id: "CustomerTransferRequest-005" }))
        return;
      }
      try {
        await updateCustomerTransferRequest(valuesFinal);
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const blnPermissionUpdateCustomerTransferRequest = checkPermission([PermissionConstants.UpdateCustomerTransferRequest]);
  const blnPermissionReviewCustomerTransferRequest = checkPermission([PermissionConstants.ReviewCustomerTransferRequest]);
  const blnSignedStatus = customerTransferFullDetail.status === Constants.signed;
  const blnApprovedOrRejectedStatus = (customerTransferFullDetail.status === Constants.approved || customerTransferFullDetail.status === Constants.rejected);
  const blnPendingStatus = customerTransferFullDetail.status === Constants.pending;
  const blnCancelledStatus = customerTransferFullDetail.status === Constants.cancelled;
  const blnIsSameBranchUser = customerTransferFullDetail.toBranchId === getBranchId();
  const blnIsSameOriginalBranch = customerTransferFullDetail.fromBranchId === getBranchId();
  const blnShowSubBranchMessage = validation.values.subBranchId && blnPermissionUpdateCustomerTransferRequest && blnIsSameOriginalBranch && blnSignedStatus && validation.values.pendingRequests.length === 0;
  const blnTransferRequestSubmitPermission = !blnPermissionUpdateCustomerTransferRequest || !blnIsSameBranchUser  || !blnPendingStatus;

  async function fetchCustomerTransferRequestDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCustomerTransferRequestWithId(id),
        getEmployeeDropdown({branchId: getBranchId(), isBranchStaff: true, isActive: true}, true)
      ]

      let resultAPI = await Promise.all(aryAPI);

      if (resultAPI) {
        if (resultAPI[0].fromBranchId !== getBranchId() && resultAPI[0].toBranchId !== getBranchId()) {
          if (getBranchUser()) {
            history.push(`/${RoutesList.notFound}`)
          }
          else {
            setBlnInvalidBranch(true);
          }
        }
        setLocalEmployeeDropdownList(resultAPI[1]);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCustomerTransferRequest, PermissionConstants.ReviewCustomerTransferRequest], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCustomerTransferRequest], true)) {
        return;
      }
    }

    fetchCustomerTransferRequestDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCustomerTransferRequestDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(async () => {
        setSuccessMessage("");
        if (!subBranchRemoveModal && !subBranchRequestAcknowledgementModal) {
          history.push(`/${RoutesList.customerTransferRequest}`)
        }
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(() => {
    if (customerTransferRequestDetail) {
      if (customerTransferRequestDetail.status == Constants.pending && viewAction && blnPermissionUpdateCustomerTransferRequest && blnIsSameBranchUser) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.customerTransferRequest}/edit/${id}`) } })
      }
    }
  }, [customerTransferRequestDetail])

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <Row>
                        <Col>
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CustomerTransferRequest" })}
                            </span>
                            <br />
                            <br />
                          </div>
                        </Col>
                      </Row>
                      <Col>
                        {displayHeaderRowData(`${intl.formatMessage({ id: "DocumentNo" })}:`, customerTransferFullDetail.customerTransferDocumentNo)}
                        {
                          blnApprovedOrRejectedStatus
                          &&
                          <>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedName" })}:`, customerTransferFullDetail.approveBy)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedDate" })}:`, moment(customerTransferFullDetail.approveOn).format(Constants.displayDateFormat))}
                          </>
                        }
                      </Col>
                    </div>
                    <Borderline />
                    <address>
                      <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                      <br />
                      <div style={{ marginTop: '8px' }}>
                        <GeneralStatus
                          status={customerTransferFullDetail.status}
                          statusColorFunction={customerTransferRequestStatusColor}
                          blnDisableCenter />
                      </div>
                    </address>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "CustomerDetail" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed" style={{ width: '100%' }}>
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Name" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "IdentityNo" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "ContactNo" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "JoinedDate" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "OriginalBranch" })}</th>
                            {
                              customerTransferFullDetail.subBranchName
                              &&
                              <th className="text-center">{intl.formatMessage({ id: "SubBranch" })}</th>
                            }
                            <th className="text-center">{intl.formatMessage({ id: "LastVisitDate" })}</th>
                            {
                              customerTransferFullDetail.lastTreatmentDate !== Constants.infiniteDateTimeWithValue
                              &&
                              <th className="text-center">{intl.formatMessage({ id: "LastTreatmentDate" })}</th>
                            }
                            <th className="text-center">{intl.formatMessage({ id: "RequestedTransferTo" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {
                                blnPermissionViewCustomer && blnIsSameOriginalBranch
                                ?
                                <Link to={customerTransferFullDetail.customerId ? `/${RoutesList.customer}/view/${customerTransferFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{`${customerTransferFullDetail.customerName} (${customerTransferFullDetail.customerNo})`}</Label>
                                </Link>
                                :
                                `${customerTransferFullDetail.customerName} (${customerTransferFullDetail.customerNo})`
                              }
                            </td>
                            <td className="text-center">{customerTransferFullDetail.identityNo}</td>
                            <td className="text-center">{customerTransferFullDetail.contactNo}</td>
                            <td className="text-center">{moment(customerTransferFullDetail.joinedDate).format(Constants.displayDateFormat)}</td>
                            <td className="text-center">{customerTransferFullDetail.fromBranchName}</td>
                            {
                              customerTransferFullDetail.subBranchName
                              &&
                              <td className={`text-center ${blnShowSubBranchMessage ? "text-danger" : ""}`}>{customerTransferFullDetail.subBranchName}</td>
                            }
                            <td className="text-center">{customerTransferFullDetail.lastVisitDate !== Constants.invalidDateTime ? moment(customerTransferFullDetail.lastVisitDate).format(Constants.displayDateAndTimeFormat) : Constants.emptyData}</td>
                            {
                              customerTransferFullDetail.lastTreatmentDate !== Constants.infiniteDateTimeWithValue
                              &&
                              <td className="text-center">{ moment(customerTransferFullDetail.lastTreatmentDate).format(Constants.displayDateAndTimeFormat)}</td>
                            }        
                            <td className="text-center">{customerTransferFullDetail.toBranchName}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {
                      blnShowSubBranchMessage
                      &&
                      <h5 className="font-size-13 mb-3 text-danger">
                        {
                          validation.values.subBranchId === validation.values.toBranchId
                          ?
                          intl.formatMessage({ id: "ApproveSameSubAndToBranch" })
                          :
                          blnPermissionDeleteCustomerSubBranchRequest
                          ?
                          <>
                            {intl.formatMessage({ id: "ApproveDifferentSubAndToBranch" }, { subBranchName: validation.values.subBranchName })}
                            <span className="font-size-13 mb-3 text-primary pointer-clickable"
                              onClick={()=> {
                                setSubBranchRemoveModal(true);
                              }}>
                              {` ${intl.formatMessage({ id: "Here" })}`}
                            </span>
                          </>
                          :
                          ""
                        }
                      </h5>
                    }
                    {
                      validation.values.pendingRequests && validation.values.pendingRequests.length > 0 && blnSignedStatus
                      &&
                      <div>
                        <div className="py-2 mt-3">
                          <h3 className="font-size-15 font-weight-bold">
                            <span style={{color: 'hotpink'}} className="margin-bottom-0">{`*`}{intl.formatMessage({ id: "PendingRequests" })}</span> 
                          </h3>
                          <p>{intl.formatMessage({id: "ReviewPendingRequests"})}</p>
                        </div>
                        <div className="">
                          <PaginationTableWithoutApi
                            title={intl.formatMessage({ id: "PendingRequests" })}
                            headerClassName={"table-pink"}
                            keyField={"id"}
                            columns={pendingRequestColumns}
                            pageSize={5}
                            options={validation.values.pendingRequests || []}
                            blnDisplayForMainListing={true} />
                        </div>
                      </div>
                    }
                    <Col xl="4">
                      <DropDownWithTitle
                        name={"toBranchConsultantId"}
                        title={intl.formatMessage({ id: "ToBranchConsultant" })}
                        specifyReturnFieldName={[{ "field": "toBranchConsultantName", "value": "preferredName" }, { "field": "toBranchConsultantId", "value": "id" }]}
                        labelField={"preferredName"}
                        valueField={"id"}
                        options={localEmployeeDropdownList}
                        disabled={viewAction || blnTransferRequestSubmitPermission}
                        initialLabel={validation.values.toBranchConsultantName}
                        initialValue={validation.values.toBranchConsultantId}
                        validationRequired={true}
                        validation={validation} />
                    </Col>
                    <div className={`py-2 mt-3`}>
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "ReasonForTransfer" })} :
                      </h3>
                    </div>
                    <GeneralTextArea
                      title=""
                      name="reasonOfChange"
                      row={5}
                      disabled={viewAction || !blnPermissionUpdateCustomerTransferRequest || Boolean(successMessage)}
                      validation={validation} />
                    {
                      blnPermissionReviewCustomerTransferRequest &&
                      (blnApprovedOrRejectedStatus || blnSignedStatus) &&
                      <>
                        <div className={`py-2 mt-3`}>
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "ReviewComment" })} :
                          </h3>
                        </div>
                        <GeneralTextArea
                          title=""
                          name="reviewComment"
                          row={5}
                          disabled={blnApprovedOrRejectedStatus || !blnPermissionReviewCustomerTransferRequest || Boolean(successMessage) || blnIsSameBranchUser || blnInvalidBranch}
                          validation={validation} />
                      </>
                    }
                    <div className="margin-top-24" />
                    {!blnCancelledStatus &&
                      <SignaturePad
                        title={intl.formatMessage({ id: "CustomerSignature" })}
                        successMessage={successMessage}
                        validation={validation}
                        disabled={blnSignedStatus || Boolean(successMessage) || viewAction}
                        isSubmitting={validation.isSubmitting} />
                    }
                    <hr className="margin-top-48" />
                    <div className="flex-direction-row d-print-none">
                      {/* {
                        !Boolean(successMessage)
                        &&
                        blnPendingStatus
                        &&
                        blnPermissionUpdateCustomerTransferRequest
                        &&
                        blnIsSameBranchUser
                        &&
                        !viewAction
                        &&
                        <MyButton
                          type={'button'}
                          class={`btn btn-warning margin-right-8`}
                          content={Constants.cancel}
                          disable={validation.isSubmitting}
                          loading={validation.isSubmitting}
                          onClick={() => {
                            setShowCancelPrompt(true);
                          }}
                        />
                      } */}
                      {
                        blnIsSameBranchUser
                        &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={viewAction}
                          validation={validation}
                          dirtyDisabled={true}
                          hidePrimaryButton={blnTransferRequestSubmitPermission}
                          showPrint={true}
                          submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.submit)
                          }}
                          extraRightButtonColor="btn-warning"
                          extraRightButtonTitle={intl.formatMessage({ id: "Cancel" })}
                          extraRightButtonFunction={() => {setShowCancelPrompt(true)}}
                          extraRightDirtyDisabled={true}
                          extraRightButtonWithoutForm={true}
                          showExtraRightButton={!Boolean(successMessage) && blnPendingStatus && blnPermissionUpdateCustomerTransferRequest && !viewAction}
                        />
                      }
                      {
                        !blnIsSameBranchUser && validation.values.pendingRequests.length < 1 && !blnInvalidBranch
                        &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          showPrint={true}
                          hidePrimaryButton={!blnSignedStatus || blnIsSameBranchUser || !blnPermissionReviewCustomerTransferRequest}
                          submitButtonTitle={intl.formatMessage({ id: "Approve" })}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.approve)
                          }}
                          extraRightDirtyDisabled={true}
                          showExtraRightButton={blnPermissionReviewCustomerTransferRequest}
                          extraRightButtonTitle={intl.formatMessage({ id: "Reject" })}
                          extraRightButtonColor="btn-danger"
                          extraRightButtonFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.reject)
                          }}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
        <CancelModal
          onCancelClick={async () => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.submitForm();
          }} />
        {
          subBranchRemoveModal
          &&
          <SubBranchRequestModal
            blnShow={subBranchRemoveModal}
            setModal={setSubBranchRemoveModal}
            customerId={customerTransferFullDetail.customerId}
            customerName={customerTransferFullDetail.customerName}
            subBranchId={customerTransferFullDetail.subBranchId}
            subBranchName={customerTransferFullDetail.subBranchName}
            subBranchConsultantId={""}
            subBranchConsultantName={""}
            setSubBranchRequestAcknowledgementModal={setSubBranchRequestAcknowledgementModal}
            setSubBranchRequestId={setSubBranchRequestId}
            deleteSubBranch={true}
          />
        }
          {
          subBranchRequestAcknowledgementModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "SubBranchRequestAcknowledgementMessage" }, { action: Constants.delete })}`}
            primaryActionTitle={`Yes`}
            showPrompt={subBranchRequestAcknowledgementModal}
            setShowPrompt={setSubBranchRequestAcknowledgementModal}
            onPrimaryClick={()=> {
              history.push(`/${RoutesList.customerSubBranchRequest}/edit/${subBranchRequestId}`); 
            }}
          />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(CustomerTransferRequestDetail);
