import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import { useLocation } from "react-router-dom";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { calculateBalanceAmount, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, reformatSubmitValueWithClass, returnPriceWithCurrency } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import CustomerOrderCart from "../CustomerOrder/subView/CustomerOrderCart";
import { useIntl } from "react-intl";
import PaymentSubview from "../CustomerOrder/subView/PaymentSubview";
import { CustomerPaymentDetailObject } from "../../app/models/customerOrder";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { EmployeeListObject } from "../../app/models/employee";
import moment from "moment";
import { ExchangeToCreditDetailListObject } from "../../app/models/customer";
import { PaymentMethodForBranchObject } from "../../app/models/branch";
import CreditRedemptionRefundModal from "./Modal/CreditRedemptionRefundModal";
import CreditRedemptionConfirmationModal from "./Modal/CreditRedemptionConfirmationModal";
import { CreditRedemptionRequestBody } from "../../app/models/crnRequest";

const CreditRedemptionPayment = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { customerStore, employeeStore, settingsStore, crnRequestStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { customerDropdownList, getCustomerAvailableCreditPayment } = customerStore;
  const { employeeDropdownList } = employeeStore;
  const { getBranchPaymentMethod } = branchStore;
  const { submitCreditRedemption } = crnRequestStore;
  const { generalSettings, getGeneralSettings} = settingsStore;
  const location = useLocation();
  const breadCrumbList = [{ title: `${intl.formatMessage({ id: "CreditRedemption" })}`, urlPath: RoutesList.creditRedemption }];
  const [creditRedemptionConfirmationModal, setCreditRedemptionConfirmationModal] = useState(false);
  const [creditRedemptionRefundModal, setCreditRedemptionRefundModal] = useState(false);
  const [blnBlockPaymentSubmit, setBlnBlockPaymentSubmit] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [localEmployeeDropdownList, setLocalEmployeeDropdownList] = useState<EmployeeListObject[]>([]);
  const [exchangeToCreditDetailList, setExchangeToCreditDetailList] = useState<ExchangeToCreditDetailListObject[]>([]);
  const [localBranchPaymentMethod, setLocalBranchPaymentMethod] = useState<PaymentMethodForBranchObject[]>([]);
  const blnPermissionCreateCreditRedemptionNoteRequest= checkPermission([PermissionConstants.CreateCreditRedemptionNoteRequest]);

  //Default Sorting Type
  const defaultSorted = [];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      paymentMethod: "",
      branchId: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      consultantIds: [],
      note: "",
      totalCount: 0,
      totalPrice: 0,
      totalSellingPrice: 0,
      totalTax: 0,
      paidNumber: 0,
      minimumPaymentAmount: 0,
      roundedMinimumPaymentAmount: 0,
      salesOrderDetails: [],
      paymentDetails: [],
      customerCartId: "",
      cartHeaderId: "",
      availableCredits: 0,
      availableProductCredits: 0,
      availableExchangedCredits: 0,
      blnDifferentBranch: false,
      originalBranchName: "",
      hasRounding: false,
      isPaymentBackdate: false,
      paymentDate: "",
      blnAccessGrantedCustomer: false,
      refundDetail: undefined
    },
    validationSchema: Yup.object({
      paymentDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PaymentMethod" }).toLowerCase() })),
      consultantIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() })),
      paymentDate: Yup.string().test("paymentDate", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PaymentDate" }) }), (value: any, context) => {
        if (context.parent.isPaymentBackdate) {
          return value;
        }
				return true;
			}).nullable(),
    }),
    onSubmit: async (values: any) => {
      try {
        if (!values.isPaymentBackdate) {
          values.paymentDate = undefined;
        }
        else {
          values.paymentDate = moment(values.paymentDate).format(Constants.defaultDateFormat);
        }

        let indexCreditPaymentFound = -1;
        let totalPaid : any = 0;
        values.paymentDetails = values.paymentDetails.map((valueCustomerPaymentDetailsTemp) => {
          totalPaid += Number(valueCustomerPaymentDetailsTemp.paidAmount)
          if (valueCustomerPaymentDetailsTemp.isCustomerCredit && (valueCustomerPaymentDetailsTemp.creditPaymentType === Constants.credit || valueCustomerPaymentDetailsTemp.creditPaymentType === Constants.productCredit)) {
            indexCreditPaymentFound = 0;
          }
          return ({
            ...valueCustomerPaymentDetailsTemp,
            transactionNo: valueCustomerPaymentDetailsTemp.transactionNo ? String(valueCustomerPaymentDetailsTemp.transactionNo): "",
          })
        })
        
        totalPaid = totalPaid.toFixed(2);
        if (totalPaid > values.totalPrice) {
          setErrorMessage(intl.formatMessage({ id: "TotalPaidAmountCouldNotMoreThanTotalPrice" }))
          return;
        }
        if (values.totalPrice > totalPaid) {
          setErrorMessage(intl.formatMessage({ id: "CreditRedemptionVarianceCreditPaymentRequire" }, { amount: returnPriceWithCurrency(values.totalPrice - totalPaid) }))
          return;
        }
        else if (Number(values.availableExchangedCredits) > 0 && indexCreditPaymentFound > -1) {
          setErrorMessage(intl.formatMessage({ id: "BlockCreditUsedWhenExchangedCreditNotFullyUtilized" }))
          return;
        }
        else if (Number(values.availableExchangedCredits) > 0 && !creditRedemptionRefundModal) {
          setCreditRedemptionRefundModal(true);
        }
        else if (!creditRedemptionConfirmationModal){
          setCreditRedemptionConfirmationModal(true);
        }
        else {
          setBlnBlockPaymentSubmit(true);
          await fetchSubmitCreditRedemption(values);
        }
      }
      catch (e) {
      }
      finally {
        setTimeout(()=> {
          setBlnBlockPaymentSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  }); 

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPackageAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchPaymentMethod(getBranchId()),
        getGeneralSettings()
      ];
      if (location.state) {
        aryAPI.push(getCustomerAvailableCreditPayment(location.state.detail.customerId))
      }
      let resultAPI = await Promise.all(aryAPI);
      let localBranchPaymentMethodTemp = _.cloneDeep(resultAPI[0]);
      setLocalBranchPaymentMethod(_.filter(localBranchPaymentMethodTemp, { isCustomerCredit: true}));
      if (resultAPI.length > 2) {
        validation.setFieldValue("availableCredits", resultAPI[2].availableCredits || 0);
        validation.setFieldValue("availableProductCredits", resultAPI[2].availableProductCredits || 0);
        validation.setFieldValue("availableExchangedCredits", resultAPI[2].availableExchangedCredits || 0);
        setExchangeToCreditDetailList(resultAPI[2].exchangeToCreditDetails);
      }  
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.CreateCreditRedemptionNoteRequest], true)) {
      return;
    }
    
    fetchPackageAPI();
  }, [])

  useEffect(() => {
    if (location.state) {
      validation.setValues({
        ...location.state.detail,
        paidNumber: location.state.detail.totalPrice,
        minimumPaymentAmount: location.state.detail.minimumPaymentAmount || 0,
        roundedMinimumPaymentAmount: location.state.detail.roundedMinimumPaymentAmount || 0,
        isPaymentBackdate: false,
        paymentDate: ""
      })
      
      setLocalEmployeeDropdownList(employeeDropdownList)
    }
    else {
      history.push(`/${RoutesList.creditRedemption}`)
    }
  }, [location]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const deletePaymentOrder = (index) => {
    let paymentDetailsTemp: any = _.cloneDeep(validation.values.paymentDetails);
    paymentDetailsTemp.splice(index, 1);
    validation.setFieldValue("paymentDetails", paymentDetailsTemp);
    let prefillPaidNumber : any = calculateBalanceAmount(validation, "paymentDetails", "totalPrice", paymentDetailsTemp);
    validation.setFieldValue("paidNumber", prefillPaidNumber);
  }

  const fetchSubmitCreditRedemption = async (values) => {
    const valuesTemp = _.cloneDeep(values);
    let valuesToSubmit : CreditRedemptionRequestBody | undefined = undefined;
    valuesToSubmit = {
      cartHeaderId: valuesTemp.customerCartId,
      customerId: valuesTemp.customerId,
      consultantIds: valuesTemp.consultantIds.map((valueTemp) => valueTemp.value),
      note: valuesTemp.note,
      hasRounding: valuesTemp.hasRounding,
      refundDetail: valuesTemp.refundDetail,
      paymentDetails: valuesTemp.paymentDetails
    }
    
    const resultSubmitCreditRedemption = await submitCreditRedemption(valuesToSubmit);
    if (resultSubmitCreditRedemption.status === Constants.success) {
      setTimeout(() => {
        setSuccessMessage("");
        history.push({ pathname: `/${RoutesList.creditRedemptionNoteRequest}/view/${resultSubmitCreditRedemption.data.creditRedemptionNoteRequestId}`});
      }, Constants.dismissSuccessMessage)
    }
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CreditRedemptionSummary" }) })}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
              <div className="h4">{intl.formatMessage({ id: "CreditRedemptionSummary" }).toUpperCase()}</div>
            </Col>
          </Row>
          <BreadCrumb
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={intl.formatMessage({ id: "CreditRedemptionSummary" })} />
          {loading && !successMessage ? (
            <Loading />
          ) : (
            <Form
              id={Constants.creditRedemptionForm}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="5" md="5" sm="12" xs="12">
                  <div className="standard-layout">
                    <CustomerOrderCart
                      validation={validation}
                      disabledCustomerOrder={true}
                      disabledNote={false}
                      disabledVoidedSalesOrderNo={true}
                      disabledCustomerPayment={disabledFieldInput}
                      deletePaymentOrder={deletePaymentOrder}
                      blnShowMinimumPaymentRequirement={true}
                      blnDisplayRounding={true} />
                  </div>
                </Col>
                <Col xl="7" md="7" sm="12" xs="12" className={`${Constants.innerWidthCompare770 > windowSize.innerWidth ? "margin-top-16" : ""}`}>
                  <div className={`standard-layout ${Constants.innerWidthCompare770 > windowSize.innerWidth ? "padding-top-0 margin-top--40" : ""}`}>
                    <PaymentSubview
                      validation={validation}
                      disabledFieldInput={disabledFieldInput}
                      branchPaymentMethod={localBranchPaymentMethod}
                      availableUnknownPaymentList={[]}
                      setAvailableUnknownPaymentList={()=> {}}
                      cardType={[]}
                      isTransferCreditEnabled={generalSettings?.isTransferCreditEnabled || false}
                      setErrorMessage={setErrorMessage}
                      getPaymentMethodOptionDropdown={()=> {}}
                    />
                    {
                      blnPermissionCreateCreditRedemptionNoteRequest
                      &&
                      !validation.values.blnAccessGrantedCustomer 
                      &&
                      <MyButton
                        type="button"
                        class="btn btn-success"
                        content={intl.formatMessage({ id: "TopUp" }).toUpperCase()}
                        disable={validation.isSubmitting}
                        loading={validation.isSubmitting}
                        onClick={() => {
                          history.push({
                            pathname: `/${RoutesList.topUpCredit}`,
                            state: {
                              detail: {
                                customerId: validation.values.customerId,
                                consultantIds: validation.values.consultantIds,
                              }
                            }
                          });
                        }}
                      />
                    }
                    <Borderline />
                    <Row>
                      <Col xl='6' md='6' xs='6'>
                        <DropDownWithTitle
                          name={"customerId"}
                          title={intl.formatMessage({ id: "Customer" })}
                          placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                          specifyReturnFieldName={[
                            {
                              field: "customerId",
                              value: "id",
                            }
                          ]}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={customerDropdownList}
                          disabled={true}
                          initialLabel={validation.values.customerName}
                          initialValue={validation.values.customerId}
                          validation={validation}
                          validationRequired={true}
                        />
                        {
                          validation.values.originalBranchName !== "" && validation.values.originalBranchName !== getBranchName()
                          &&
                          <p className="text-danger margin-top--12">*{intl.formatMessage({ id: "OriginalBranch" })}: {validation.values.originalBranchName} { validation.values.blnAccessGrantedCustomer && ` (${intl.formatMessage({ id: "AccessGranted" })})`}</p>
                        }
                      </Col>
                      <Col xl='6'>
                        <DropDownWithTitleMultiSelect
                          name={"consultantIds"}
                          title={"Consultants"}
                          specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                          returnFieldWithLabel={true}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={localEmployeeDropdownList}
                          disabled={disabledFieldInput || validation.values.blnDifferentBranch}
                          initialValue={validation.values.consultantIds}
                          validationRequired={true}
                          validation={validation} />
                      </Col>
                    </Row>
                    <p className="margin-left-8 mb-0" style={{ color: 'grey' }}>
                      {validation.values.customerNo} | {validation.values.customerMobileNo}
                    </p>
                    <Borderline />
                    <Row>
                      <Col xl={4}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="button"
                              class="btn btn-back"
                              style={{ width: '100%' }}
                              content={intl.formatMessage({ id: "Back" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                history.push({
                                  pathname: `/${RoutesList.creditRedemption}`,
                                  state: {
                                    detail: {
                                      customerCartId: validation.values.customerCartId
                                    }
                                  }
                                });
                              }}
                            />
                          )}
                      </Col>
                      <Col xl={8}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="submit"
                              class="btn btn-primary"
                              style={{ width: '100%', marginTop: Constants.innerWidthCompare>windowSize.innerWidth ? "8px" : "0px" }}
                              content={intl.formatMessage({ id: "ConfirmRedemption" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          {
            creditRedemptionRefundModal
            &&
            <CreditRedemptionRefundModal 
              blnShow={creditRedemptionRefundModal}
              setModal={setCreditRedemptionRefundModal}
              creditNoteAmount={Number(validation.values.availableExchangedCredits)}
              setCreditRedemptionConfirmationModal={setCreditRedemptionConfirmationModal}
              refundProcessingFeeRate={generalSettings?.refundProcessingFeeRate || 0}
              validation={validation}/>
          }
          {
            creditRedemptionConfirmationModal
            &&
            <CreditRedemptionConfirmationModal
              blnShow={creditRedemptionConfirmationModal}
              setModal={setCreditRedemptionConfirmationModal}
              validation={validation}
              employeeDropdownList={localEmployeeDropdownList}
              exchangeToCreditDetailList={exchangeToCreditDetailList}
              blnBlockPaymentSubmit={blnBlockPaymentSubmit} />
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(CreditRedemptionPayment);
