import { useEffect, useState } from "react";
import { Col, Label, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { arraySpliceInAllValue, checkPermission, getBranchId, newSpaceBeforeCapitalLetter, returnPaymentIcon, returnSwitchTabButtonUI, returnThousandSeperator } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import PaginationTable from "../../../app/components/table/PaginationTable";
import { isTablet } from "react-device-detect";
import TableActionButton from "../../../app/components/table/TableActionButton";
import CreditCardRefundIcon from 'mdi-react/CreditCardRefundIcon'; 
import { PaginationRequestBody } from "../../../app/models/pagination";
import SelectWithTableListModal from "../../../app/components/modal/SelectWithTableListModal";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { useFormik } from "formik";
import { RoutesList } from "../../../app/constants/RoutesList";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { Link } from "react-router-dom";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  creditPaymentType: StaticReferenceDataObject[];
  availableCredits: number;
  lockedCredits: number;
  availableProductCredits: number;
  lockedProductCredits: number;
  availableExchangedCredits: number;
  lockedExchangedCredits: number;
  blnPermissionManageExchangeToCredit: boolean;
  blnPermissionManageCreditAdjustment: boolean;
  blnPermissionManageCustomerSalesOrder: boolean;
  blnPermissionManageCustomerProductReturn: boolean;
  blnPermissionManageVirtualWarehouseCreditExchange: boolean;
  blnPermissionManageProductRedemptionNoteReturn: boolean;
  blnPermissionManageAppolousSales: boolean;
  onClickViewExchangeToCreditDetail: Function;
  onClickViewCreditAdjustment: Function;
}

export default observer(function CreditHistoryModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { purchasedCreditStore, customerStore, commonStore } = useStore();
  const { windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { purchasedCreditList, purchasedCreditHistoryList, getPurchasedCredit, getPurchasedCreditHistory, purchasedCreditTotalItems, purchasedCreditHistoryTotalItems, setPurchasedCreditList, setPurchasedCreditHistoryList, setPurchasedCreditTotalItems, setPurchasedCreditHistoryTotalItems } = purchasedCreditStore;
  const { customerCreditHistoryList, getCustomerCreditHistory, customerCreditHistoryTotalItems } = customerStore;
  const [blnCreditPurchased, setBlnCreditPurchased] = useState(true);
  const [showCompletedPurchasedCredit, setShowCompletedPurchasedCredit] = useState(false);
  const [loadingCreditPurchased, setLoadingCreditPurchased] = useState(false);
  const [currentPageCreditPurchase, setCurrentPageCreditPurchase] = useState(1);
  const [currentPageCreditHistory, setCurrentPageCreditHistory] = useState(1);
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [creditPaymentTypeList, setCreditPaymentTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedCreditType, setSelectedCreditType] = useState<string>(AllType);
  const [selectedCreditTypeId, setSelectedCreditTypeId] = useState<number | string>(" ");
  const [purchasedCreditHistoryModal, setPurchasedCreditHistoryModal] = useState(false);
  const [purchasedCreditHistoryId, setPurchasedCreditHistoryId] = useState(false);
  const showCompletedButtonWidth = "150px";
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);

  // validation
  const validation = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
    }
  })

  let purchasedCreditRequestBody : PaginationRequestBody = {
    pageNumber: currentPageCreditPurchase,
    pageSize: pageSize,
    customerId: props.customerId,
    currentBranchId: getBranchId(),
    creditType: selectedCreditTypeId,
    showCompleted: showCompletedPurchasedCredit
  }
  
  useEffect(()=> {
    async function fetchCreditPurchased() {
      setLoadingCreditPurchased(true);
      await getPurchasedCredit(purchasedCreditRequestBody);
      setLoadingCreditPurchased(false);
      setInitialCallAPI(false);
    }
    
    fetchCreditPurchased();
    let creditPaymentTypeTemp = _.cloneDeep(props.creditPaymentType);
    setCreditPaymentTypeList(arraySpliceInAllValue(creditPaymentTypeTemp, AllType))
  }, [])

  useEffect(() => {
    if ((selectedCreditTypeId || selectedCreditTypeId === 0 ) && !initialCallAPI) {
      fetchPurchasedCreditApi();
    }
  }, [selectedCreditTypeId, showCompletedPurchasedCredit])

  async function fetchPurchasedCreditApi() {
    setLoadingCreditPurchased(true);
    await getPurchasedCredit(purchasedCreditRequestBody);
    setLoadingCreditPurchased(false);
  }

  async function fetchCustomerCreditHistory() {
    setLoadingCreditPurchased(true);
    await getCustomerCreditHistory({
      pageNumber: 1,
      pageSize: pageSize,
      customerId: props.customerId
    })
    setLoadingCreditPurchased(false);
  }

  //Table Content UI
  const CreditPurchaseColumns = [
    {
      dataField: "creditType",
      text: intl.formatMessage({ id: "CreditType" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {newSpaceBeforeCapitalLetter(row.creditType)}
        </>,
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerSalesOrder && row.documentType === Constants.salesOrder
            ?
            <Link to={`/${RoutesList.customerSalesOrder}/view/${row.documentId}`} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.documentNo || Constants.emptyData}</Label>
            </Link>
            :
            blnPermissionManageExchangeToCredit && row.documentType === Constants.exchangeToCredit
            ?
            <Link to={`/${RoutesList.exchangeToCredit}/view/${row.documentId}`} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.documentNo || Constants.emptyData}</Label>
            </Link>
            :
            // blnPermissionManageExchangeToCredit && valueExchangeToCreditDetailTemp.documentType === Constants.returnRedemption
            // ?
            // <Link to={`/${RoutesList.exchangeToCredit}/view/${valueExchangeToCreditDetailTemp.documentId}`} target={Constants.blank} rel="noopener noreferrer">
            //   <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{valueExchangeToCreditDetailTemp.documentNo || Constants.emptyData}</Label>
            // </Link>
            // :
            <div>
              {row.documentNo || Constants.emptyData}
            </div>
          }
        </>
      )
    },
    {
      dataField: "createdDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdDate).format(Constants.displayDateFormat)}
          </div>
          <div className="">
            {moment(row.createdDate).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "initialBalance",
      text: intl.formatMessage({ id: "InitialBalance" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {returnThousandSeperator(row.initialBalance)}
        </>,
    },
    {
      dataField: "newBalance",
      text: intl.formatMessage({ id: "NewBalance" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {returnThousandSeperator(row.newBalance)}
        </>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
          {
              row.availableActions.map((valueAvailableAction, indexAction)=> {
                let buttonType : any = String(valueAvailableAction).toLowerCase()
                let blnView = valueAvailableAction === Constants.view;
                let blnRefund = valueAvailableAction === Constants.refund;

                return (
                  <div key={`value_${valueAvailableAction}_${indexAction}`}>
                    <TableActionButton
                      onClick={async () => {
                        if (blnView) {
                          setLoadingCreditPurchased(true);
                          await getPurchasedCreditHistory({purchasedCreditId: row.id, pageSize: pageSize, pageNumber: 1})
                          setPurchasedCreditHistoryId(row.id);
                          setPurchasedCreditHistoryModal(true);
                          setLoadingCreditPurchased(false);
                        }
                        else if (blnRefund) {
                          
                        }
                      }}
                      type={buttonType}
                      {...blnRefund && {iconReplaceUI: <CreditCardRefundIcon className="text-dark" size={18} id="creditCardRefundTooltip" />}}
                      {...blnRefund && {iconId: "creditCardRefundTooltip"}}
                      {...blnRefund && {iconToolTipText: `${intl.formatMessage({ id: "Refund" })}`}}
                      />
                  </div>
                )
              })
            }
          </div>
        </>
      ),
    },
  ];

  const CreditPurchaseHistoryColumns = [
    {
      dataField: "action",
      text: intl.formatMessage({ id: "CreditDetail" }).toUpperCase(),
      sort: true,
      style: {
        width: '30%'
      },
    },
    {
      dataField: "entityType",
      text: intl.formatMessage({ id: "EntityType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.entityType)}</div>,
    },

    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdAt).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.createdAt).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "creditAmount",
      text: intl.formatMessage({ id: "Credits" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={returnThousandSeperator(row.creditAmount)}
          statusBackgroundColor={Math.sign(row.creditAmount) === -1 ? Constants.red : Constants.lightgreen} />
      ),
    }
  ];

  const CreditHistoryColumns = [
    {
      dataField: "action",
      text: intl.formatMessage({ id: "CreditDetail" }).toUpperCase(),
      sort: true,
      style: {
        width: '30%'
      },
    },
    {
      dataField: "entityType",
      text: intl.formatMessage({ id: "EntityType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.entityType)}</div>,
    },

    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdAt).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.createdAt).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "credits",
      text: intl.formatMessage({ id: "Credits" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={returnThousandSeperator(row.credits)}
          statusBackgroundColor={Math.sign(row.credits) === -1 ? Constants.red : Constants.lightgreen} />
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>,
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      style: {
        width: '5%'
      },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkViewCreditHistoryPermission(row)
            }
          </div>
        </>
      ),
    },
  ];

  const checkViewCreditHistoryPermission = (row) => {
    if (row.entityType === Constants.salesOrderToCredit || row.entityType === Constants.exchangeToCredit) {
      return (
        props.blnPermissionManageExchangeToCredit
        &&
        <TableActionButton
          onClick={() => props.onClickViewExchangeToCreditDetail(row.entityId)}
          type="view" />
      );
    }
    if (row.entityType === Constants.creditToCash || row.entityType === Constants.adjustCredit) {
      return (
        props.blnPermissionManageCreditAdjustment
        &&
        <TableActionButton
          onClick={() => props.onClickViewCreditAdjustment(row.entityId)}
          type="view" />
      );
    }
    if (row.entityType === Constants.payWithCredit || row.entityType === Constants.purchaseCustomerCredit || row.entityType === Constants.reversePurchaseCustomerCredit || row.entityType === Constants.revertPayWithCredit) {
      return (
        props.blnPermissionManageCustomerSalesOrder
        &&
        <TableActionButton
          to={(`/${RoutesList.customerSalesOrder}/view/${row.entityId}`)}
          type="view" />
      );
    }
    if (row.entityType === Constants.productReturnToCredit) {
      return (
        props.blnPermissionManageCustomerProductReturn
        &&
        <TableActionButton
          to={(`/${RoutesList.customerProductReturn}/view/${row.entityId}`)}
          type="view" />
      );
    }
    if (row.entityType === Constants.customerVirtualWarehouseCreditExchange) {
      return (
        props.blnPermissionManageVirtualWarehouseCreditExchange
        &&
        <TableActionButton
          to={(`/${RoutesList.virtualWarehouseCreditExchange}/view/${row.entityId}`)}
          type="view" />
      );
    }
    if (row.entityType === Constants.productRedemptionNoteToCredit) {
      return (
        props.blnPermissionManageProductRedemptionNoteReturn
        &&
        <TableActionButton
          to={(`/${RoutesList.productRedemptionNoteReturn}/view/${row.entityId}`)}
          type="view" />
      );
    }
    if (row.entityType === Constants.payAppolousWithCredit || row.entityType === Constants.reversePayAppolousWithCredit) {
      return (
        props.blnPermissionManageAppolousSales
        &&
        <TableActionButton
          to={(`/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.entityId}`)}
          type="view" />
      );
    }

    return Constants.emptyDataV2
  }

  const hideModal = () => {
    if (loadingCreditPurchased) {
      return;
    }

    setLoadingCreditPurchased(false);
    setPurchasedCreditList([]);
    setPurchasedCreditHistoryList([]);
    setPurchasedCreditTotalItems(0);
    setPurchasedCreditHistoryTotalItems(0);
    setCreditPaymentTypeList([]);
    props.setModal(false);
  }

  const returnCreditTiles = (displayName, availableCredit, lockedCredit, valueTextColor) => {
    return (
      <div 
        className="card-radio" 
        style={{
          borderColor: displayName === Constants.exchangedCredit ? Constants.red : displayName === Constants.productCredit ? Constants.green : Constants.lightpurple,
          borderRadius: '10px',
          marginTop: Constants.innerWidthCompare991 >= windowSize.innerWidth ? "8px" : "",
          padding: "0.5rem",
          paddingTop: "14px",
          pointerEvents: "none"
        }}>
        <div style={{textAlign: "center"}}>
          {returnPaymentIcon(displayName)}
          <span style={{marginLeft: "8px", fontWeight: "500"}}>{intl.formatMessage({ id: displayName })}</span>
        </div>
        <hr style={{margin:"8px", marginLeft: "0px", marginRight: "0px"}}/>
        <div className="flex-align-items-center-without-height">
          <div style={{borderRight: "solid #eff2f7", borderRightWidth: "1px", flex:1, textAlign: "center" }}>
            <p className="mb-0 text-muted">{intl.formatMessage({ id: "Available" })}</p>
            <p className={`mb-0 ${valueTextColor}`} style={{fontSize: "15px"}}>{returnThousandSeperator(availableCredit)}</p>
          </div>
          <div style={{flex: 1, textAlign: "center"}}>
            <p className="mb-0 text-muted">{intl.formatMessage({ id: "Processing" })}</p>
            <p className={`mb-0 ${valueTextColor}`} style={{fontSize: "15px"}}>{returnThousandSeperator(lockedCredit)}</p>
          </div>
        </div>
      </div>
    )
  }

  const onClickShowCompletedCreditPurchased = () => {
    setShowCompletedPurchasedCredit((valueShowCompletedPurchasedCredit)=> !valueShowCompletedPurchasedCredit);
  }

  const onClickCreditPurchaseTab = () => {
    setBlnCreditPurchased(true);
  }

  const onClickCreditHistoryTab = () => {
    setBlnCreditPurchased(false);
    if (customerCreditHistoryList.length < 1 && !loadingCreditPurchased) {
      fetchCustomerCreditHistory();
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="xl"
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        <div className="modal-header">
          <div className="flex-direction-row" style={{flex:1 }}>
            <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "ClientCredits" })}</h5>
            {
              !loadingCreditPurchased
              &&
              <button
                type="button"
                onClick={() => {
                  hideModal();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </div>
        </div>
        <div className="modal-body">
          <Row>
            <Col xl={4} lg={4}>
              {returnCreditTiles(Constants.exchangedCredit, props.availableExchangedCredits, props.lockedExchangedCredits, "text-danger")}
            </Col>
            <Col xl={4} lg={4}>
              {returnCreditTiles(Constants.productCredit, props.availableProductCredits, props.lockedProductCredits, "text-success")}
            </Col>
            <Col xl={4} lg={4}>
              {returnCreditTiles(Constants.credit, props.availableCredits, props.lockedCredits, "text-primary")}
            </Col>
          </Row>
          <div className={`btn-group mt-3 ${blnCreditPurchased ? "mb-4" : ""} flex-wrap`} role="group">
            {returnSwitchTabButtonUI("blnAllRecord", "blnAllRecordTrue", blnCreditPurchased, loadingCreditPurchased, intl.formatMessage({ id: "CreditPurchase" }), onClickCreditPurchaseTab, "150px")}
            {returnSwitchTabButtonUI("blnAllRecord", "blnAllRecordFalse", !blnCreditPurchased, loadingCreditPurchased, intl.formatMessage({ id: "CreditHistory" }), onClickCreditHistoryTab, "150px")}
          </div>
          {
            blnCreditPurchased
            &&
            <div className="flex-align-items-center-without-height">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "CreditType" })}
                name="creditType"
                className="mb-0"
                styles={{width: "40%"}}
                options={creditPaymentTypeList}
                labelField="displayValue"
                valueField="key"
                blnValueWithNewSpace={true}
                initialLabel={selectedCreditType}
                initialValue={selectedCreditTypeId}
                setFieldLabel={setSelectedCreditType}
                setFieldValue={setSelectedCreditTypeId}
                disabled={loadingCreditPurchased}
                validationRequired={true}
                onChange={() => {
                  setCurrentPageCreditPurchase(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
              <div className="btn-group margin-left-12 margin-top-26" role="group">
                {returnSwitchTabButtonUI("blnShowCompletedCreditPurchased", "blnShowCompletedCreditPurchasedFalse", showCompletedPurchasedCredit, loadingCreditPurchased, intl.formatMessage({ id: "ShowCompleted" }), onClickShowCompletedCreditPurchased, showCompletedButtonWidth, "btn-outline-info")}
              </div>
            </div>
          }
          {
            loadingCreditPurchased
            ?
            <Loading />
            :
            blnCreditPurchased
            ?
            <PaginationTable
              currentPage={currentPageCreditPurchase}
              setCurrentPage={setCurrentPageCreditPurchase}
              pageSize={pageSize}
              totalItem={purchasedCreditTotalItems}
              tableColumns={CreditPurchaseColumns}
              data={purchasedCreditList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditPurchase" }) })}
              objSorted={[]}
              customerId={props.customerId}
              currentBranchId={getBranchId()}
              creditType={selectedCreditTypeId}
              showCompleted={showCompletedPurchasedCredit}              
              requestAPI={getPurchasedCredit}
              blnRemoveBackground={true} />
            :
            <PaginationTable
              currentPage={currentPageCreditHistory}
              setCurrentPage={setCurrentPageCreditHistory}
              pageSize={pageSize}
              totalItem={customerCreditHistoryTotalItems}
              tableColumns={CreditHistoryColumns}
              data={customerCreditHistoryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditHistory" }) })}
              objSorted={[]}
              customerId={props.customerId}
              requestAPI={getCustomerCreditHistory}
              blnRemoveBackground={true} />
          }
        </div>
        {
          purchasedCreditHistoryModal &&
          <SelectWithTableListModal
            blnShow={purchasedCreditHistoryModal}
            setModal={setPurchasedCreditHistoryModal}
            title={intl.formatMessage({ id: "CreditPurchaseHistory" })}
            options={purchasedCreditHistoryList}
            keyField={"entityId"}
            fieldName={"entityId"}
            columns={CreditPurchaseHistoryColumns}
            validation={validation}
            blnPagination={true}
            totalItem={purchasedCreditHistoryTotalItems}
            requestAPI={getPurchasedCreditHistory}
            paginationSubmitField={{ purchasedCreditId: purchasedCreditHistoryId }} />
        }
      </Modal>
  )
})