import { MandatoryProductsObject } from "./customerOrder";

export interface CustomerSalesOrderListObject {
	id: string;
	customerSalesOrderNo: string;
	orderDate: string;
	branchName: string;
	customerName: string;
	status: string;
	subTotalAmount: number;
	taxAmount: number;
	discountAmount: number;
	totalAmount: number;
	totalPayment: number;
	balance: number;
	deposit: number;
	note: string;
	paymentStatus: string;
}

export interface CustomerSalesOrderObject {
	cartHeaderId: string;
	note: string;
	customerId: string;
	consultantIds: string[];
	paymentDetails: SalesOrderPaymentDetailObject[];
}

export class CustomerSalesOrderFormValues {
	customerCartId: string = "";
	cartHeaderId: string = "";
	note: string = "";
	customerId: string = "";
	consultantIds: string[] = [];
	salesOrderDetails: SalesOrderDetailObject[] = [];
	paymentDetails: SalesOrderPaymentDetailObject[]= [];
	branchId: string = "";
  hasRounding: boolean = false;
  paymentDate: string = "";
}

export interface CustomerSalesOrderUpdateObject {
	id: string;
	note: string;
	workflowAction: string;
}

export interface CustomerSalesOrderDetailObject {
	id: string;
	customerSalesOrderNo: string;
  customerInvoiceNo: string;
	branchName: string;
	branchId: string;
	customerName: string;
	customerId: string;
	customerBranchName: string;
	customerBranchId: string;
  businessEntityName: string;
  companyNumber: string;
  consultantIds: [];
  consultants: [];
  consultantBranchId: string;
	status: string;
	subTotalAmount: number;
	taxAmount: number;
	discountAmount: number;
  rounding: number;
	totalAmount: number;
	totalPayment: number;
	balance: number;
	deposit: number;
	totalRefundAmount: number;
	note: string;
	isPaymentAdjustmentAllowed: boolean;
	isUnderPaymentAdjustment: boolean;
	isVoidAllowed: boolean;
	issueDate: string;
	submissionDate: string;
	signatureImage: string;
	salesOrderDetails: SalesOrderDetailObject[];
	paymentHistories: PaymentHistoryObject[];
	customerDeliverOrders: CustomerDeliverOrderObject[];
	customerInvoices: CustomerInvoiceObject[];
  cartId: string;
	reviewRemark: string;
	voidDate: string;
	reviewedOn: string;
	reviewerUserName: string;
	isAllowRegenerateProductDO: boolean;
	isAccessOwnBranchCustomer: boolean;
	hasActiveCustomerAccess: boolean;
	isEditConsultantAllowed: boolean;
	voidedSalesOrderId: string;
  voidedSalesOrderNo: string;
  totalInvoiceAmount: number;
  totalCreditPayment: number;
  isAllowUpdateStatus: boolean;
  subBrandId: string;
  subBrandName: string;
  isChangePaymentMethodAllowed: boolean;
  isUnderChangePaymentMethod: boolean;
  isAllowUpdateSubBrand: boolean;
  purchaseBranchId: string;
  purchaseBranchName: string;
  isUnderChangePaymentMode: boolean;
  tinId: string;
  tinDisplayName: string;
}

export interface CustomerSalesOrderPaymentObject {
	salesOrderId: string;
	branchId: string;
	payments: SalesOrderPaymentDetailObject[];
  paymentDate?: string;
}

export interface CustomerSalesOrderPaymentSuccess {
	invoiceId: string;
	salesOrderId: string;
	customerDeliveryOrderId: string;
}

export interface SalesOrderPaymentDetailObject {
	paymentMethodId: string;
	paymentMethodName: string;
	paidAmount: number;
	last4CardDigit: number;
	cardType: string;
	transactionNo: string;
	optionType: string;
  paymentMethodOptionName: string;
  paymentMethodOptionId: string;
  last4CardDigitNo: string;
  creditPaymentType: string;
}

export interface SalesOrderDetailObject {
	id: string;
	purchaseType: string;
	count: number;
	itemId: string;
	itemName: string;
	itemDesc: string;
	discountType: string;
	discountValue: number;
	expiredDate: Date;
	unitPrice: number;
	taxRate: number;
	taxAmount: number;
	taxedPrice: number;
	discountedPrice: number;
	discountAmount: number;
	totalPrice: number;
	remark: string;
	serviceById: string;
	serviceProductUsedIds: string[];
	serviceProductUsed: string[];
	additionalData: AdditionalDataObject;
	additionalFormData: AdditionalFormDataObject[];
}

export interface AdditionalDataObject {
	additionalProp1: string;
	additionalProp2: string;
	additionalProp3: string;
}

export interface PaymentHistoryObject {
	id: string;
	paymentDate: Date;
	paymentMethodName: string;
	optionName: string;
	paidAmount: number;
	last4CardDigit: number;
	cardType: string;
	transactionNo: string;
  isCreditPayment: boolean;
}

export interface AdditionalFormDataObject {
	id: string;
	name: string;
	controlType: string;
	rows: number;
	options: string[];
	placeHolder: string;
	isRequired: boolean;
	isActive: boolean;
	displayOrder: number;
	value: string;
}

export interface DeliverOrderDetailObject {
	redeemType: string;
	performUserId: string;
	performUserName: string;
	packageId: string;
	packageName: string;
	redeemDetailId: string;
	redeemDetailName: string;
	count: number;
	description: string;
}

export interface CustomerDeliverOrderObject {
	id: string;
	isDelete: boolean;
	customerDeliverOrderNo: string;
	customerInvoiceId: string;
	customerInvoiceNo: string;
	businessEntityId: string;
	businessEntityName: string;
	branchId: string;
	branchName: string;
	customerId: string;
	customerPreferredName: string;
	customerFullName: string;
	customerContactNo: string;
	customerAddressLine1: string;
	customerAddressLine2: string;
	customerCity: string;
	customerPostcode: string;
	customerCountryName: string;
	customerStateName: string;
	performDateTime: Date;
	signatureImage: string;
	status: string;
	deliverOrderDetails: DeliverOrderDetailObject[];
}

export interface InvoiceDetailObject {
	id: string;
	customerInvoiceId: string;
	purchaseType: string;
	currencyId: string;
	currencyName: string;
	currencyFormat: string;
	count: number;
	itemId: string;
	itemName: string;
	itemDesc: string;
	expiredDate: Date;
	costPrice: number;
	unitPrice: number;
	taxRate: number;
	taxAmount: number;
	taxedPrice: number;
	discountValue: string;
	discountedPrice: number;
	discountAmount: number;
	totalPrice: number;
	remark: string;
}


export interface PaymentHistory2Object {
	id: string;
	title: string;
	paymentDate: Date;
	customerInvoiceId: string;
	paymentMethodId: string;
	paymentMethodName: string;
	paidAmount: number;
	branchId: string;
	branchName: string;
	last4CardDigit: number;
	cardType: string;
	optionName: string;
	transactionNo: string;
}

export interface CustomerInvoiceObject {
	id: string;
	invoiceNo: string;
	issueDate: Date;
	branchId: string;
	branchName: string;
	customerId: string;
	customerName: string;
	consultantIds: string[];
	consultants: string[];
	paymentStatus: string;
	balance: number;
	subTotalAmount: number;
	subTotalPackagesAmount: number;
	subTotalServicesAmount: number;
	subTotalProductsAmount: number;
	subTotalDiscountAmount: number;
	subTotalTaxAmount: number;
	totalAmount: number;
	refundAmount: number;
	brandName: string;
	businessEntityName: string;
	branchAddress: string;
	branchContactNo: string;
	issueBy: string;
	note: string;
	invoiceFooterNote: string;
	signatureImage: string;
	signDate: string;
	invoiceDetails: InvoiceDetailObject[];
	paymentHistories: PaymentHistory2Object[];
}
export interface CustomerSalesOrderRedeemListObject {
  salesOrderId: string;
  salesOrderNo: string;
  status: string;
  paymentStatus: string;
  balance: number;
  redeemCustomerSalesOrderDetail: RedeemCustomerSalesOrderDetailObject[];
}
export interface RedeemCustomerSalesOrderDetailObject {
  salesOrderDetailId: string;
  purchaseType: string;
  salesOrderDetailRedeemType: string;
  salesOrderDetailRedeemId: string;
  salesOrderDetailRedeemName: string;
  packageName: string;
  packageTotalRedeemCount: string;
  packageTotalAvailableRedeemCount: number;
  packageRedeemedCount: string;
  totalRedeemCount: number;
  availableRedeemCount: number;
  redeemedCount: number;
  availablePrice: number,
  redeemedPrice: number,
  totalPrice: number,
  selectedProductIds: string[];
  additionalData: AdditionalDataObject;
  isOptional: boolean;
  optionalProducts: OptionalProductObject[];
  mandatoryProducts: MandatoryProductsObject[];
  optionalBranchMachines: OptionalBranchMachineObject[];
  status: string;
	promotionCode: string;
}
export interface OptionalProductObject {
  productId: string;
  productName: string;
}
export interface OptionalBranchMachineObject {
  branchMachineId: string;
  branchMachineName: string;
  serialNumber: string;
  consumeQuantity?: number;
}
export interface CustomerSalesOrderRedeemSubmitObject {
  customerId: string;
  branchId: string;
  note: string;
  redeemCustomerSalesOrderRequestDetail: RedeemCustomerSalesOrderRequestDetailObject[];
}
export interface RedeemCustomerSalesOrderRequestDetailObject {
  salesOrderId: string;
  salesOrderDetailId: string;
  salesOrderDetailRedeemType: string;
  salesOrderDetailRedeemId: string;
  count: number;
  serviceById: string;
  selectedProductIds: string[];
  selectedMachines: SelectedMachinedObject[];
  additionalData: AdditionalDataObject;
  remark: string;
}
export interface CustomerSalesOrderRedeemSuccessObject {
  redeemedDeliverOrders: CustomerDeliverOrderObject[];
}
export interface CustomerDeliverOrderObject {
  customerDeliverOrderId: string;
  customerDeliverOrderNo: string;
}

export interface RefundDetail {
	purchaseType: string;
	purchaseId: string;
	purchaseName: string;
	quantity: number;
	remainingSession: number;
	remainingProduct: number;
	remainingOptionalProductCost: number;
	exchangeAmount: number;
	promotionCode: string;
}
export interface CustomerSalesOrderUpgradeList  {
  salesOrderId: string;
  salesOrderNo: string;
  branchId: string;
  branchName: string;
  salesOrderDetailId: string;
  packageId: string;
  packageName: string;
  packageSession: number;
  packageAvailableSession: number;
  packageRedeemedSession: number;
  packageMaxRedeemProductQuantity: number;
  packageRedeemedProductQuantity: number;
  packageOptionalProductCost: number;
  packageRedeemedProductCost: number;
  packageExpiredDate: Date;
  count: number;
  unitPrice: number;
  status: string;
  isUpgradePackage: boolean;
  packageGroupCodeId: string;
  availableServices: AvailableServiceObject[];
  availableProducts: AvailableProductObject[];
}
export interface AvailableServiceObject {
  serviceTreatmentId: string;
  serviceTreatmentName: string;
  quantity: number;
  costPrice: number;
  unitPrice: number;
  total: number;
  breakdownAmount: number;
  availableSession: number;
  redeemedSession: number;
  availableProducts: AvailableProductForServiceObject[];
}
export interface AvailableProductForServiceObject {
  productId: string;
  productName: string;
  measurementId: string;
  measurementName: string;
  isOptional: boolean;
  consumeQuantity: number;
  consumePercentage: number;
}
export interface AvailableProductObject {
  productId: string;
  productName: string;
  isOptional: boolean;
  quantity: number;
  availableQuantity: number;
  redeemedQuantity: number;
  costPrice: number;
  unitPrice: number;
  total: number;
}

export interface CustomerSalesOrderUpgradeSubmitObject {
  salesOrderDetailId: string;
  branchId: string;
  consultantIds: string[];
  expiredDate: string;
  note: string;
  isBackdate: boolean;
  submissionDate: string;
  customerPackageDetails: CustomerPackageDetailObject[];
  customerPaymentDetails: CustomerPaymentDetailObject[];
}
export interface CustomerPackageDetailObject {
  serviceTreatmentId: string;
  count: number;
}
export interface CustomerPaymentDetailObject {
  paymentMethodId: string;
  paymentMethodName: string;
  paidAmount: number;
  last4CardDigit: number;
  cardType: string;
  transactionNo: string;
  optionType: string;
  paymentMethodOptionName: string;
  paymentMethodOptionId: string;
  last4CardDigitNo: string;
  creditPaymentType: string
}
export interface CustomerSalesOrderUpgradeResponseObject {
  invoiceId: string;
  invoiceNo: string;
	salesOrderId: string;
}
export class CustomerSalesOrderUpgradeFormValues {
	salesOrderDetailId: string = "";
  branchId: string = "";
  consultantIds: string[] = [];
  expiredDate: string = "";
  note: string = "";
  isBackdate: boolean = false;
  submissionDate: string = "";
  customerPackageDetails: CustomerPackageDetailObject[] = [];
  customerPaymentDetails: CustomerPaymentDetailObject[] = [];
  hasRounding: boolean = false;
}

export interface CustomerDetailTemporary {
  id: string;
  preferredName: string;
  customerNo: string;
  mobileNo: string;
}

export interface CustomerSalesOrderAddPaymentSuccess {
	invoiceId: string;
	isProductDOAutoGenerated: boolean;
  deliverOrderId: string;
  deliverOrderNo: string;
}

export interface CustomerSalesOrderUpdateConsultantObject {
  salesOrderId: string;
  consultantIds: [];
}
export interface CustomerSalesOrderReceiptObject {
  id: string;
  isDelete: boolean;
  paymentDate: Date;
  paymentReceiptNo: string;
  businessEntityId: string;
  brandName: string;
  businessEntityName: string;
  companyNumber: string;
  branchId: string;
  branchName: string;
  branchAddress: string;
  branchContactNo: string;
  customerId: string;
  customerName: string;
  customerNo: string;
  customerAddress: string;
  customerMobileNo: string;
  subTotal: number;
  total: number;
  serviceBy: string;
  note: string;
  paymentBranchName: string;
  paymentBranchAddress: string;
  paymentBranchContactNo: string;
  paymentReceiptDetails: PaymentReceiptDetailObject[];
}
export interface PaymentReceiptDetailObject {
  id: string;
  name: string;
  paymentMethodName: string;
  optionName: string;
  paidAmount: number;
  last4CardDigit: number;
  cardType: string;
  transactionNo: string;
  chargeRate: number;
  chargeAmount: number;
  isVoid: boolean;
}

export interface CustomerSalesOrderSignObject  {
	id: string;
	signatureImage: string;
}

export interface CustomerSalesOrderVoidObject  {
	id: string;
	reviewRemark?: string;
	workflowAction?: string;
}

export interface CustomerSalesOrderSiteMapObject {
	customerSalesOrderId: string;
  customerSalesOrderNo: string;
  branchName: string;
  customerName: string;
  status: string;
	submissionDate: string;
  isVoidAllowed: boolean;
  paymentHistories: PaymentHistorySiteMapObject[];
  customerDeliverOrders: CustomerDeliverOrderSiteMapObject[];
	paymentAdjustments: PaymentAdjustmentObject[];
  creditExchangeRequests: CreditExchangeRequestObject[];
	productRedemptions: ProductRedemptionObject[];
  virtualWarehouseReversalTransactions: VirtualWarehouseReversalTransactionObject[];
	timeLineDetails: any[];
}
export interface PaymentHistorySiteMapObject {
  id: string;
  paymentDate: string;
  paymentMethodName: string;
  optionName: any;
  paidAmount: number;
  last4CardDigit: number;
  cardType: any;
  transactionNo: string;
  paymentMethodOptionId: any;
  chargeRate: number;
  chargeAmount: number;
  paymentReceiptNo: string;
  last4CardDigitNo: string;
}
export interface CustomerDeliverOrderSiteMapObject {
  customerDeliverOrderNo: string;
  performDateTime: string;
  status: string;
  deliverOrderDetails: DeliverOrderSiteMapDetail[];
  id: string;
  isDelete: boolean;
  isVoidAllowed: boolean;
  isSystemAutoGenerated: boolean;
}

export interface DeliverOrderSiteMapDetail {
  redeemType: string;
  count: number;
  redeemDetailName: string;
  serviceById: any;
  serviceByName: any;
  packageName: any;
}

export interface CreditExchangeRequestObject {
  id: string;
  documentNo: string;
  issueDate: string;
  branchName: string;
  status: string;
  subTotalExchangeAmount: number;
  processingFees: number;
  totalExchangeAmount: number;
  remark: string;
  reason: string;
  details: DetailObject[];
}

interface DetailObject {
  purchaseType: string;
  purchaseName: string;
  quantity: number;
  exchangeAmount: number;
  promotionCode: string;
}
export interface PaymentAdjustmentObject {
  id: string;
  customerPaymentAdjustmentNo: string;
  branchName: string;
  status: string;
  adjustmentDate: string;
  actionDate: string;
  actionUserName: string;
  requestUserName: string;
  remark: string;
  oldPaymentAmount: number;
  oldPaymentMethods: string;
  newPaymentAmount: number;
  newPaymentMethods: string;
  type: string;
}

export interface ProductRedemptionObject {
  id: string;
  productRedemptionNo: string;
  performedDateTime: string;
  status: string;
  productRedemptionNoteDetails: ProductRedemptionNoteDetailObject[];
}

export interface ProductRedemptionNoteDetailObject {
  virtualWarehouseNo: string;
  productName: string;
  quantity: number;
}

export interface CustomerSalesOrderCommissionObject {
  salesOrderNo: string;
  totalPackageSales: number;
  totalProductSales: number;
  totalProductCommission: number;
  totalActualPackageSales: number;
  totalActualProductSales: number;
  totalActualProductCommission: number;
  totalSalesAdjustmentAmount: number;
  totalSalesAdjustmentProductAmount: number;
  totalSalesAdjustmentProductCommission: number;
  commissionSummaryDetails: CommissionSummaryDetailObject[];
  salesAdjustments: SalesAdjustmentObject[];
}

export interface CommissionSummaryDetailObject {
  date: string;
  paymentInfo: string;
  consultantNames: string[];
  receivedAmounts: number[];
  packageSalesAmounts: number[];
  productSalesAmounts: number[];
  productCommissionAmounts: number[];
  randomId: string;
}

export interface SalesAdjustmentObject {
  performedDateTime: string;
  branchId: string;
  branchName: string;
  salesAdjustmentType: string;
  amount: number;
  productAmount: number;
  productCommission: number;
  remark: string;
  randomId: string;
}

export interface PackageUpgradeObject {
  customerId: string;
  customerName: string;
  customerNo: string;
  customerMobileNo: string;
  customerBranchId: string;
  customerSalesOrderId: string;
  salesOrderDetailId: string;
  customerPackageName: string;
  branchId: string;
  consultantIds: any;
  expiredDate: string;
  note: string;
  customerPackageDetails: any;
  customerPaymentDetails: any
  availableCredits: number;
  availableProductCredits: number;
  isBackdate: boolean;
  submissionDate: string;
  packageCustomerOrderDropdownList: any;
  hasRounding: boolean,
  totalRoundingPrice: number,
  totalPriceBeforeRounding: number;
}

export interface SelectedMachinedObject {
  branchMachineId: string;
  consumeQuantity: number;
}

export interface CustomerSalesOrderUpdateSubBrandObject  {
	salesOrderId: string;
	subBrandId: string;
}

export interface CustomerSalesOrderProductRedemptionListObject {
  documentId: string;
  documentNo: string;
  branchName: string;
  productName: string;
  performDateTime: string;
  status: string;
  count: number;
  isRedeemFromVirtualWarehouse: boolean;
  detail: string;
  randomId: string;
}

export interface VirtualWarehouseReversalTransactionObject {
  virtualWarehouseId: string;
  virtualWarehouseNo: string;
  performDateTime: string;
  branchName: string;
  productName: string;
  quantity: number;
  status: string;
}

