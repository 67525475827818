import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getPurchaseTypeIcon, IncludesLocationPathName, returnPriceWithCurrency, displayPromotionCode, exchangeToCreditStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label, FormFeedback } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ExchangeToCreditDetailListObject } from "../../app/models/returnRedemption";
import MyButton from "../../app/components/form/MyButton";

const ReturnRedemptionDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { returnRedemptionStore, commonStore } = useStore();
  const { successMessage, loading, setLoading } = commonStore;
  const { returnRedemptionDetail, setReturnRedemptionDetail, getReturnRedemptionWithId, reviewReturnRedemption } = returnRedemptionStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ReturnRedemption" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "ReturnRedemption" }), urlPath: RoutesList.returnRedemption }];
  const returnRedemptionFullDetail = returnRedemptionDetail || {
    id: "",
    documentNo: "",
    issueBy: "",
    issueDate: "",
    businessEntityId: "",
    businessEntityName: "",
    branchId: "",
    branchName: "",
    customerId: "",
    customerName: "",
    salesOrderId: "",
    salesOrderNo: "",
    status: "",
    totalExchangeAmount: 0,
    remark: "",
    reason: "",
    exchangeToCreditDetails: [],
    salesOrderPaymentStatus: "",
    actionBy: "",
    actionOn: ""
  }

  // const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionReviewExchangeToCredit = checkPermission([PermissionConstants.ReviewExchangeToCredit]);

  const blnPendingStatus = returnRedemptionFullDetail.status === Constants.pending;

  // validation
  const defaultReviewReturnRedemptionDetails = {
    id: "",
    remark: "",
    workflowAction: "",
  }
  const [reviewReturnRedemptionDetails, setReviewReturnRedemptionDetails] = useState(defaultReviewReturnRedemptionDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })
  const [localLoading, setLocalLoading] = useState(false);

  async function fetchReturnRedemptionDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getReturnRedemptionWithId(id),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageExchangeToCredit], true)) {
      return;
    }

    fetchReturnRedemptionDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setReturnRedemptionDetail(undefined);
      }
    })
  }, []);

  const onClickReviewReturnRedemption = async (valueAction: string) => {
    setLocalLoading(true);
    await reviewReturnRedemption({ id: id, remark: reviewReturnRedemptionDetails.remark, workflowAction: valueAction })
    setLocalLoading(false);
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }
  
  const displayTableRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={2} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="returnRedemption-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "ReturnRedemption" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {returnRedemptionFullDetail.branchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DocumentNo" })}:`, returnRedemptionFullDetail.documentNo)}
                            {
                              displayHeaderRowData(
                                `${intl.formatMessage({ id: "CreditRedemptionNoteNo" })}:`,
                                //  blnPermissionManageCustomerSalesOrder 
                                //  ? 
                                //  <Link to={returnRedemptionFullDetail.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${returnRedemptionFullDetail.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                //   <Label className="margin-bottom-0 pointer-clickable">{returnRedemptionFullDetail.salesOrderNo}</Label>
                                // </Link> 
                                // :  
                                returnRedemptionFullDetail.salesOrderNo
                              )
                            }
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DateAndTime" })}:`, `${moment(returnRedemptionFullDetail.issueDate).format(Constants.displayDateFormat)}\n${moment(returnRedemptionFullDetail.issueDate).format(Constants.displayTimeFormat)}`)}
                          </div>
                        </Col>
                        {
                          !blnPendingStatus 
                          &&
                          <Col xs="6" className="text-end text-muted margin-top-26">
                            <address>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedBy" })}:`, returnRedemptionFullDetail.actionBy)}
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedOn" })}:`, `${moment(returnRedemptionFullDetail.actionOn).format(Constants.displayDateFormat)}\n${moment(returnRedemptionFullDetail.actionOn).format(Constants.displayTimeFormat)}`)}
                            </address>
                          </Col>
                        }
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <strong>{intl.formatMessage({ id: "Customer" })}:</strong>
                        <div style={{fontSize: "14px"}}>
                          {
                            blnPermissionManageCustomer 
                            ? 
                            <Link to={returnRedemptionFullDetail.customerId ? `/${RoutesList.customer}/view/${returnRedemptionFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                             <Label className="margin-bottom-0 pointer-clickable">{returnRedemptionFullDetail.customerName || Constants.emptyData}</Label>
                            </Link>
                            :  
                            returnRedemptionFullDetail.customerName
                          }
                        </div>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={returnRedemptionFullDetail.status}
                              statusColorFunction={exchangeToCreditStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    {
                      returnRedemptionFullDetail.exchangeToCreditDetails && returnRedemptionFullDetail.exchangeToCreditDetails.length > 0 &&
                      <>
                        <div className="py-2 mt-4">
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "ReturnRedemptionItem" })}
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-fixed">
                            <thead className="table-light">
                              <tr>
                                <th style={{ width: '30%' }}>{intl.formatMessage({ id: "Name" })}</th>
                                <th>{intl.formatMessage({ id: "Type" })}</th>
                                <th className="text-end">{intl.formatMessage({ id: "ReturnRedemptionAmount" })}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                returnRedemptionFullDetail.exchangeToCreditDetails.map((value: ExchangeToCreditDetailListObject, index) => (
                                  <tr key={index}>
                                    <td>{getPurchaseTypeIcon(value.purchaseType)}{value.purchaseName}</td>
                                    <td>{value.purchaseType}</td>
                                    <td className="text-end">{returnPriceWithCurrency(value.exchangeAmount.toFixed(2))}</td>
                                  </tr>
                                ))
                              }
                              {displayTableRowData(`${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(returnRedemptionFullDetail.totalExchangeAmount))}
                              {displayTableRowData(`${intl.formatMessage({ id: "TotalReturnRedemptionAmount" })}`, returnPriceWithCurrency(returnRedemptionFullDetail.totalExchangeAmount))}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    }
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Reason" })}
                      name="reason"
                      className="mt-3"
                      row={5}
                      field={returnRedemptionFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true}
                    />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Review" })}
                      name="remark"
                      className={`${errorState.remark ? "mb-0" : "mb-3"} mt-4`}
                      row={4}
                      validationRequired={errorState.remark}
                      field={blnPendingStatus ? reviewReturnRedemptionDetails : returnRedemptionFullDetail}
                      setFieldValue={setReviewReturnRedemptionDetails}
                      disabled={localLoading || !blnPendingStatus || Boolean(successMessage) || !blnPermissionReviewExchangeToCredit}/>
                    {errorState.remark ? (
                      <FormFeedback className="font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                    ) : null}
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        blnPermissionReviewExchangeToCredit
                        &&
                        blnPendingStatus
                        &&
                        !Boolean(successMessage)
                        &&
                        <>
                        <MyButton
                          type="button"
                          class="btn btn-success margin-right-8"
                          content={intl.formatMessage({ id: "Approve" })}
                          onClick={() => {
                            onClickReviewReturnRedemption(Constants.approve);
                          }}
                          disable={localLoading}
                          loading={localLoading}
                        />
                        <MyButton
                          type="button"
                          class="btn btn-danger"
                          content={intl.formatMessage({ id: "Reject" })}
                          onClick={() => {
                            onClickReviewReturnRedemption(Constants.reject);
                          }}
                          disable={localLoading}
                          loading={localLoading}
                        />
                      </>
                      }
                      {
                        !successMessage &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={blnPendingStatus && blnPermissionReviewExchangeToCredit}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(ReturnRedemptionDetail);
