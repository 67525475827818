import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import _ from 'lodash';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, returnPriceWithCurrency, salesOrderStatusColor } from "../../app/common/function/function";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import Loading from "../../app/components/loading/Loading";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import ReportExpandRow from "../Report/subView/ReportExpandRow";
import { ObjectForReportRow } from "../../app/models/common";
import moment from "moment";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import MyButton from "../../app/components/form/MyButton";

const ContractLiabilityList = () => {
  const intl = useIntl();
  //Use Store
  const { contractLiabilityStore, customerStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { contractLiabilityList, contractLiabilityTransactionList, contractLiabilityTransactionTotalItem, getContractLiability, getContractLiabilityTransaction, setContractLiabilityList, setContractLiabilityTransactionList } = contractLiabilityStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);
  const pageSize = Constants.defaultPageSize;

  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");

  const aryContractLiablityDetails: ObjectForReportRow[] = [
    { label: "PurchaseType", value: "purchaseType", type: "string" },
    { label: "Item", value: "itemName", type: "string" },
    { label: "OriginalAmount", value: "originalAmount", type: "currency" },
    { label: "UtilizedAmount", value: "utilizedAmount", type: "currency" },
    { label: "BalanceAmount", value: "balanceAmount", type: "currency" },
    { label: "CreditNoteAmount", value: "creditNoteAmount", type: "currency" },
    { label: "ExchangeToCreditAmount", value: "exchangeToCreditAmount", type: "currency" },
    { label: "TransferAmount", value: "transferAmount", type: "currency" },
    { label: "Status", value: "status", type: "string" },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractLiabilityNo: "",
      salesOrderNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  let contractLiabilityRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    contractLiabilityNo: validation.values.contractLiabilityNo,
    salesOrderNo: validation.values.salesOrderNo,
    customerId: selectedCustomerId
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchContractLiabilityAPI() {
      setLoading(true);
      await getContractLiability(contractLiabilityRequestBody);
      await getContractLiabilityTransaction(contractLiabilityRequestBody);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageContractLiability], true)) {
      return;
    }

    fetchContractLiabilityAPI();

    return (() => {
      setContractLiabilityList([]);
      setContractLiabilityTransactionList([]);
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchContractLiabilityApi(true);
    }
  }, [triggerSearch, selectedCustomerId])

  async function fetchContractLiabilityApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getContractLiability({...contractLiabilityRequestBody, pageNumber: currentPage});
    await getContractLiabilityTransaction({...contractLiabilityRequestBody, pageNumber: currentPageTransaction});
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setCurrentPageTransaction(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  //Table Content UI
  const ContractLiabilityColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "contractLiabilityNo",
      text: intl.formatMessage({ id: "ContractLiabilityNo" }).toUpperCase()
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase(),
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
    },
    {
      dataField: "originalAmount",
      text: intl.formatMessage({ id: "OriginalAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.originalAmount)}</div>
      ),
    },
    {
      dataField: "utilizedAmount",
      text: intl.formatMessage({ id: "UtilizedAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.utilizedAmount)}</div>
      ),
    },
    {
      dataField: "balanceAmount",
      text: intl.formatMessage({ id: "BalanceAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.balanceAmount)}</div>
      ),
    },
    {
      dataField: "creditNoteAmount",
      text: intl.formatMessage({ id: "CreditNoteAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.creditNoteAmount)}</div>
      ),
    },
    {
      dataField: "exchangeToCreditAmount",
      text: intl.formatMessage({ id: "ExchangeToCreditAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.exchangeToCreditAmount)}</div>
      ),
    },
    {
      dataField: "transferAmount",
      text: intl.formatMessage({ id: "TransferAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.transferAmount)}</div>
      ),
    },
    {
      dataField: "commissionRate",
      text: intl.formatMessage({ id: "CommissionRate" }).toUpperCase()
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor}/>
      )
    },
    {
      dataField: "isDelete",
      text: intl.formatMessage({ id: "Delete" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isDelete ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
  ];

  const ContractLiabilityTransactionColumns = [
    {
      dataField: "contractLiabilityNo",
      text: intl.formatMessage({ id: "ContractLiabilityNo" }).toUpperCase()
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.amount)}</div>
      ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
    },
    {
      dataField: "isPosted",
      text: intl.formatMessage({ id: "Posted" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isPosted ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "ActionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "postedDate",
      text: intl.formatMessage({ id: "PostedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.postedDate
        ?
        <>
          <div className="">
            {`${moment(row.postedDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.postedDate).format(Constants.displayTimeFormat)}
          </div>
        </>
        :
        Constants.emptyDataV2
      ),
    },
  ]

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ContractLiabilityDetails" })}
        rowFieldName={"contractLiabilityDetails"}
        rowColumns={aryContractLiablityDetails}
        keyFieldName={"id"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown("", {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ContractLiability" }) })}
        title={intl.formatMessage({ id: "ContractLiability" })}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "ContractLiabilityNo" })}
              name="contractLiabilityNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterContractLiabilityNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "SalesOrderNo" })}
              name="salesOrderNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterSalesOrderNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Customer" })}
              name="customer"
              placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
              options={customerDropdownList}
              labelField="preferredName"
              valueField="id"
              initialLabel={selectedCustomer}
              initialValue={selectedCustomerId}
              setFieldLabel={setSelectedCustomer}
              setFieldValue={setSelectedCustomerId}
              disabled={localLoading || loading}
              onChange={() => {
                clearCustomerDropdownList();
                setCurrentPage(1);
                setCurrentPageTransaction(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
              blnSupportCustomSearch={true}
              onChangeCustomSearch={(value)=> {
                fetchCustomerDropdown(value);
              }}
              onMenuOpenFunction={()=> {
                clearCustomerDropdownList();
                setLoadingCustomer(false);
              }}
              loadingCustomSearch={loadingCustomer}
              setLoadingCustomSearch={setLoadingCustomer}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchContractLiabilityApi(true);
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            title="Contract Liability List"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ContractLiabilityColumns}
            data={contractLiabilityList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ContractLiability" }) })}
            objSorted={defaultSorted}
            expandRow={expandRow}
            contractLiabilityNo={validation.values.contractLiabilityNo}
            salesOrderNo={validation.values.salesOrderNo}
            customerId={selectedCustomerId}
            requestAPI={getContractLiability} />
        }
        <hr/>
        {
          localLoading
          ?
          <Loading/>
          :
          <PaginationTable
            title="Contract Liability Transaction"
            currentPage={currentPageTransaction}
            setCurrentPage={setCurrentPageTransaction}
            pageSize={pageSize}
            totalItem={contractLiabilityTransactionTotalItem}
            tableColumns={ContractLiabilityTransactionColumns}
            data={contractLiabilityTransactionList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: "Contract Liability Transaction" })}
            objSorted={defaultSorted}
            contractLiabilityNo={validation.values.contractLiabilityNo}
            salesOrderNo={validation.values.salesOrderNo}
            customerId={selectedCustomerId}
            requestAPI={getContractLiabilityTransaction} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ContractLiabilityList);