import { PaginationRequestBody } from "../models/pagination";
import { ExchangeToCreditAddObject, ExchangeToCreditDetailObject, ExchangeToCreditListObject, ExchangeToCreditPreviewGetObject, ExchangeToCreditPreviewObject, ExchangeToCreditReviewObject } from "../models/exchangeToCredit";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class exchangeToCreditStore {
  exchangeToCreditList: ExchangeToCreditListObject[] = [];
  exchangeToCreditDetail: ExchangeToCreditDetailObject | undefined = undefined;
  exchangeToCreditPaginationParams: PaginationRequestBody | undefined = undefined;
  exchangeToCreditPreview: ExchangeToCreditPreviewObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.exchangeToCreditList = [];
    this.exchangeToCreditDetail = undefined;
    this.exchangeToCreditPaginationParams = undefined;
    this.exchangeToCreditPreview = undefined;
  }

  setExchangeToCreditList = (exchangeToCreditList: ExchangeToCreditListObject[]) => {
    this.exchangeToCreditList = exchangeToCreditList;
  }

  setExchangeToCreditDetail = (exchangeToCreditDetail: ExchangeToCreditDetailObject | undefined) => {
    this.exchangeToCreditDetail = exchangeToCreditDetail;
  }

  setExchangeToCreditPaginationParams = (exchangeToCreditPaginationParams: PaginationRequestBody | undefined) => {
    this.exchangeToCreditPaginationParams = exchangeToCreditPaginationParams;
  }

  setExchangeToCreditPreview = (exchangeToCreditPreview: ExchangeToCreditPreviewObject | undefined) => {
    this.exchangeToCreditPreview = exchangeToCreditPreview;
  }

  getExchangeToCredit = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      this.setExchangeToCreditPaginationParams(PaginationRequestBody);
      const resultExchangeToCredit = await agent.ExchangeToCredit.exchangeToCreditList(PaginationRequestBody);
      runInAction(() => {
        this.exchangeToCreditList = resultExchangeToCredit.data;
        store.commonStore.setTotalItem(resultExchangeToCredit.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.exchangeToCreditList = [];
    }
  }

  getExchangeToCreditWithId = async (id: string) => {
    try{
      const resultExchangeToCreditDetail = await agent.ExchangeToCredit.exchangeToCreditDetail(id);
      runInAction(() => {
        if (resultExchangeToCreditDetail.exchangeToCreditDetails) {
          resultExchangeToCreditDetail.exchangeToCreditDetails = resultExchangeToCreditDetail.exchangeToCreditDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
        }
        this.exchangeToCreditDetail = resultExchangeToCreditDetail;
      });
      return Promise.resolve(resultExchangeToCreditDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.exchangeToCreditDetail = undefined;
    }
  }

  getExchangeToCreditPreview = async (exchangeToCreditRequestBody: ExchangeToCreditPreviewGetObject) => {
    try{
      const resultExchangeToCreditPreview = await agent.ExchangeToCredit.exchangeToCreditPreviewDetail(exchangeToCreditRequestBody);
      runInAction(() => {
        this.exchangeToCreditPreview = resultExchangeToCreditPreview;
      });
      return Promise.resolve(resultExchangeToCreditPreview)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.exchangeToCreditPreview = undefined;
    }
  }

  addExchangeToCredit = async (exchangeToCreditRequestBody: ExchangeToCreditAddObject) => {
    try{
      await agent.ExchangeToCredit.addExchangeToCredit(exchangeToCreditRequestBody);
      store.commonStore.setSuccessMessage(`ExchangeToCreditRequestSubmitSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  reviewExchangeToCredit = async (reviewRefundCreditRequestBody: ExchangeToCreditReviewObject) => {
    try{
      await agent.ExchangeToCredit.reviewExchangeToCredit(reviewRefundCreditRequestBody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`ExchangeToCreditRequestReviewSuccess`)
      ), 100)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}