import { useState, useEffect } from "react";
import { FormFeedback, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, displayPromotionCode, getPurchaseTypeIcon, returnPriceWithCurrency, exchangeToCreditStatusColor } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { ExchangeToCreditDetailListObject, ExchangeToCreditDetailObject } from "../../../app/models/exchangeToCredit";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import moment from "moment";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import GeneralInputWithClickableUI from "../../../app/components/form/GeneralInputWithClickableUI";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedExchangeToCreditDetail: ExchangeToCreditDetailObject;
  setSelectedExchangeToCreditDetail: Function;
  reviewExchangeToCredit?: Function;
}

export default observer(function ExchangeToCreditDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingReview, setLoadingReview] = useState(false);

  const defaultReviewExchangeToCreditDetails = {
    id: "",
    remark: "",
    workflowAction: "",
  }
  const [reviewExchangeToCreditDetails, setReviewExchangeToCreditDetails] = useState(defaultReviewExchangeToCreditDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })
  const blnPermissionReviewExchangeToCredit = checkPermission([PermissionConstants.ReviewExchangeToCredit])
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const hideModal = () => {
    setReviewExchangeToCreditDetails(defaultReviewExchangeToCreditDetails);
    props.setModal(false);
  }

  useEffect(() => {
    if (props.selectedExchangeToCreditDetail) {
      validationLocal.setFieldValue("customerId", props.selectedExchangeToCreditDetail.customerId);
      validationLocal.setFieldValue("customerName", props.selectedExchangeToCreditDetail.customerName);
      validationLocal.setFieldValue("salesOrderId", props.selectedExchangeToCreditDetail.salesOrderId);
      validationLocal.setFieldValue("salesOrderNo", props.selectedExchangeToCreditDetail.salesOrderNo);
      validationLocal.setFieldValue("documentNo", props.selectedExchangeToCreditDetail.documentNo);
      validationLocal.setFieldValue("branchName", props.selectedExchangeToCreditDetail.branchName);
      validationLocal.setFieldValue("actionBy", props.selectedExchangeToCreditDetail.actionBy || Constants.emptyData);
      validationLocal.setFieldValue("actionOn", (props.selectedExchangeToCreditDetail.actionOn !== Constants.invalidDateTimeWithValue && props.selectedExchangeToCreditDetail.actionOn !== Constants.invalidDateTime) ? moment(props.selectedExchangeToCreditDetail.actionOn).format(Constants.displayDateAndTimeFormat) : Constants.emptyData)
    }
  }, [props.selectedExchangeToCreditDetail])

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      customerId: "",
      branchName: "",
      salesOrderId: "",
      salesOrderNo: "",
      documentNo: "",
      actionBy: "",
      actionOn: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const blnPartialPayment = props.selectedExchangeToCreditDetail.salesOrderPaymentStatus === Constants.partial;
  const blnPendingStatus = props.selectedExchangeToCreditDetail.status === Constants.pending;

  const displayHeaderRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={2} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const getPaymentStatus = () => {
    if (props.selectedExchangeToCreditDetail.salesOrderPaymentStatus === Constants.paid) {
      return <b className="font-size-13 text-success">{`(${intl.formatMessage({ id: "FullyPaid" })})`}</b>;
    }
    else if (props.selectedExchangeToCreditDetail.salesOrderPaymentStatus === Constants.partial) {
        return <b className="font-size-13 text-warning">{`(${intl.formatMessage({ id: "PartiallyPaid" })})`}</b>;
    }
  }

  const onClickReviewExchangeToCredit = async (valueAction: string) => {
    let reviewCreditAdjustmentTemp = _.cloneDeep({ ...reviewExchangeToCreditDetails, id: props.selectedExchangeToCreditDetail.id, workflowAction: valueAction })
    setReviewExchangeToCreditDetails(reviewCreditAdjustmentTemp);
    if (props.reviewExchangeToCredit) {
      setLoadingReview(true);
      await props.reviewExchangeToCredit(reviewCreditAdjustmentTemp)
      setLoadingReview(false);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingReview && !successMessage) {
            hideModal();
          }
        }}
        size={"lg"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          !props.selectedExchangeToCreditDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-direction-row" style={{flex:1 }}>
                  <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "ExchangeToCredit" }) })} {getPaymentStatus()}</h5>
                  <GeneralStatus
                    className="margin-right-20"
                    status={props.selectedExchangeToCreditDetail.status}
                    statusColorFunction={exchangeToCreditStatusColor}/>
                </div>
                {
                  !loadingReview && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "Customer" })}
                      name="customerName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                      onClickFunction={()=> {
                        if (blnPermissionManageCustomer) {
                          window.open(`/${RoutesList.customer}/view/${validationLocal.values.customerId}`)
                        }
                      }}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Branch" })}
                      name="branchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "DocumentNo" })}
                      name="documentNo"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "SalesOrderNo" })}
                      name="salesOrderNo"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                      onClickFunction={()=> {
                        if (blnPermissionManageCustomerSalesOrder) {
                          window.open(`/${RoutesList.customerSalesOrder}/view/${validationLocal.values.salesOrderId}`)
                        }
                      }}/>
                  </DoubleColumnRowParent>
                </Row>
                {
                  !blnPendingStatus &&
                  <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ReviewedBy" })}
                      name="actionBy"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <div
                      onClick={()=> {
                        if (blnPermissionManageCustomerSalesOrder) {
                          window.open(`/${RoutesList.customerSalesOrder}/view/${validationLocal.values.salesOrderId}`)
                        }
                      }}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "ReviewedOn" })}
                        name="actionOn"
                        type="text"
                        disabled={true}
                        validationRequired={true}
                        validation={validationLocal}
                      />
                    </div>
                  </DoubleColumnRowParent>
                </Row>
                }

                <Borderline />
                {
                  props.selectedExchangeToCreditDetail.exchangeToCreditDetails && props.selectedExchangeToCreditDetail.exchangeToCreditDetails.length > 0 &&
                  <>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "ExchangeToCreditItem" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th style={{ width: '30%' }}>{intl.formatMessage({ id: "Name" })}</th>
                            <th className={!blnPartialPayment ? "text-center" : "text-end"} colSpan={!blnPartialPayment ? 1 : 2}>{intl.formatMessage({ id: "Type" })}</th>
                            {!blnPartialPayment && <th className="text-end">{intl.formatMessage({ id: "ExchangeableAmount" })}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.selectedExchangeToCreditDetail.exchangeToCreditDetails.map((value: ExchangeToCreditDetailListObject, index) => (
                              <tr key={index}>
                                <td className="">{getPurchaseTypeIcon(value.purchaseType)}{value.purchaseName} {displayPromotionCode(value.promotionCode)}</td>
                                <td className={!blnPartialPayment ? "text-center" : "text-end"} colSpan={!blnPartialPayment ? 1 : 2}>{value.purchaseType}</td>
                                {!blnPartialPayment && <td className="text-end">{returnPriceWithCurrency(value.exchangeAmount.toFixed(2))}</td>}
                              </tr>
                            ))
                          }
                          {displayHeaderRowData(`${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(props.selectedExchangeToCreditDetail.totalExchangeAmount))}
                          {displayHeaderRowData(`${intl.formatMessage({ id: "TotalExchangeAmount" })}`, returnPriceWithCurrency(props.selectedExchangeToCreditDetail.totalExchangeAmount))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                }
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "Reason" })}
                  name="reason"
                  className="mt-3"
                  row={5}
                  field={props.selectedExchangeToCreditDetail}
                  setFieldValue={props.setSelectedExchangeToCreditDetail}
                  disabled={true}
                />
                <>
                  <Borderline />
                  <GeneralTextAreaForModal
                    title={intl.formatMessage({ id: "Review" })}
                    name="remark"
                    className={errorState.remark ? "mb-0" : "mb-3"}
                    row={4}
                    validationRequired={errorState.remark}
                    field={blnPendingStatus ? reviewExchangeToCreditDetails : props.selectedExchangeToCreditDetail}
                    setFieldValue={setReviewExchangeToCreditDetails}
                    disabled={loadingReview || !blnPendingStatus || Boolean(successMessage) || !blnPermissionReviewExchangeToCredit} />
                  {errorState.remark ? (
                    <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                  ) : null}
                </>
                <div className="mb-2 mt-4 flex-right">
                  {
                    blnPendingStatus
                    &&
                    blnPermissionReviewExchangeToCredit
                    &&
                    !Boolean(successMessage)
                    &&
                    <>
                      <MyButton
                        type="button"
                        class="btn btn-danger margin-right-8"
                        style={{ width: '20%' }}
                        content={Constants.reject}
                        onClick={() => {
                          let errorStateTemp = {
                            adjustmentType: false,
                            creditAdjustmentAmount: false,
                            reason: false,
                            accountHolderName: false,
                            identityNo: false,
                            bankAccountType: false,
                            bankAccountNo: false,
                            remark: false,
                          };
                          let blnErrorFound = false;
                          if (!reviewExchangeToCreditDetails.remark) {
                            errorStateTemp.remark = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickReviewExchangeToCredit(Constants.reject);
                          }
                        }}
                        disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                      <MyButton
                        type="submit"
                        class="btn btn-success"
                        style={{ width: '20%' }}
                        content={Constants.approve}
                        onClick={() => {
                          onClickReviewExchangeToCredit(Constants.approve);
                        }}
                        disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                    </>
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})